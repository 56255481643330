<template>
  <div>
    <v-card>
      <v-card-title class="bg-red white--text"> Approve </v-card-title>
      <v-card-text>
        <ApproveDataTable
          :headers="headers"
          :desserts="desserts"
          transactionType="ถอนเครดิต"
          :meta="meta"
          @approveItem="approveItem"
          @deleteItem="deleteItem"
          @selectPage="selectPage"
          @setCurrentPage="setCurrentPage"
        />
      </v-card-text>
    </v-card>
    <MemberDetailDialog
      :dialog="approveMessageDialog"
      :memberData="memberData"
      @onClose="value => (approveMessageDialog = value)"
    />
  </div>
</template>

<script>
import ApproveDataTable from "@/components/tables/ApproveDatatable";
import MemberDetailDialog from "./../dialogs/MemberDetailDialog";
import TransactionService from "./../../../../services/transaction.service";
export default {
  props: {
    fetchData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        { text: "ธุรกรรม", value: "id" },
        { text: "Username", value: "member.username" },
        { text: "Name", value: "member.name" },
        { text: "Bank", value: "member.bank" },
        { text: "Account number", value: "member.account_number" },
        { text: "Amount", value: "amount" },
        { text: "หมายเหตุ", value: "description" },
        // { text: "API", value: "status_api_auto" },
        { text: "สร้างโดย", value: "create_by.name" },
        { text: "วันที่สมัคร", value: "created_at" },
        { text: "Actions", value: "actions" }
      ],
      params: {
        page: 1,
        limit: 10,
        transaction_type: "withdraw"
      },
      desserts: [],
      meta: {},
      approveMessageDialog: false,
      memberData: null
    };
  },
  methods: {
    async findWithdraw(query) {
      const { data, status } = await TransactionService.findWithdrawPending(
        query
      );
      if (status === 200) {
        this.desserts = data.items;
        this.meta = data.meta;
      }
    },
    async approveItem(value) {
      const { id, withdrawWithAPI } = value;
      console.log(withdrawWithAPI);
      // return;
      const { data, status } = await TransactionService.approveWithDrawAPI(id, {
        withdrawWithAPI: withdrawWithAPI
      });
      if (status === 200) {
        this.memberData = data;
        this.approveMessageDialog = true;
        this.findWithdraw(this.params);
        const room = this.memberData.member.id.toString();
        this.$emit("approveStatus", "success");
        this.$socket.emit("setSocketNotificationAdminApproveWithdraw");
        this.$socket.emit("setSocketNotificationWithdraw", room);
        this.$socket.emit("setSocketNotificationApproveWithdraw", room);
      }
    },
    async deleteItem(value) {
      if (!value) return false;
      const { id } = value;
      const deleted = await TransactionService.delete(id);

      if (deleted.status === 200) {
        this.findWithdraw(this.params);
      }
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findWithdraw(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findWithdraw(this.params);
      }
    }
  },

  mounted() {
    this.$nextTick(() => this.findWithdraw(this.params));

    this.sockets.subscribe("getSocketNotificationAdminWithdraw", response => {
      if (response === "fetchWithdraw") this.findWithdraw(this.params);
    });
    this.sockets.subscribe(
      "getSocketNotificationAdminApproveWithdraw",
      response => {
        if (response === "fetchApproveWithdraw") this.findWithdraw(this.params);
      }
    );
  },
  watch: {
    fetchData(value) {
      value && this.findWithdraw(this.params);
    }
  },
  components: {
    ApproveDataTable,
    MemberDetailDialog
  }
};
</script>
