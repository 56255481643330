<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.sms_time`]="{ item }">
        <span v-if="item.sms_time">{{ item.sms_time | dateTimeSMSLocal }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>

      <template v-slot:[`item.bonus`]="{ item }">
        <v-select
          :items="bonusItems"
          v-model="item.bonus"
          item-value="id"
          item-text="title"
          label="bonus"
          dense
          clearable
          hide-details=""
          outlined
        ></v-select>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="approveItem(item)">
          mdi-check
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">ลบ</h1>
          <p class="text-sm font-light">คุณต้องการที่จะลบรายการนี้ หรือไม่?</p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmDeleteDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="comfirmDelete">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approveDialog" persistent max-width="600">
      <v-card tile flat v-if="approveData">
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">{{ transactionType }}</h1>
          <v-divider></v-divider>
          <div class="my-4 text-center">
            <v-row no-gutters justify="center">
              <v-col cols="10">
                <v-row no-gutters class="font-bold text-lg px-4">
                  <v-col cols="6" class="text-right px-4">Username: </v-col>
                  <v-col cols="6" class="text-left px-4">
                    {{ approveData.member.username }}</v-col
                  >
                </v-row>
                <v-row no-gutters class="font-bold text-lg px-4">
                  <v-col cols="6" class="text-right px-4">จำนวน:</v-col>
                  <v-col cols="6" class="text-left px-4">
                    {{ approveData.amount }}</v-col
                  >
                </v-row>
                <v-row no-gutters class="text-sm px-4">
                  <v-col cols="6" class="text-right px-4">ชื่อ:</v-col>
                  <v-col cols="6" class="text-left px-4">
                    {{ approveData.member.name }}</v-col
                  >
                </v-row>

                <v-row
                  no-gutters
                  class="text-sm px-4"
                  v-if="transactionType === 'ถอนเครดิต'"
                >
                  <v-col cols="6" class="text-right px-4"> ธนาคาร:</v-col>
                  <v-col cols="6" class="text-left px-4">
                    <v-avatar
                      size="40"
                      :color="approveData.member.bank | bankColor"
                      class="mr-2 pa-2"
                    >
                      <v-img
                        :src="approveData.member.bank | bankImage"
                        contain
                      ></v-img>
                    </v-avatar>
                    {{ approveData.member.bank }}</v-col
                  >
                </v-row>
                <v-row
                  no-gutters
                  class="text-sm px-4"
                  v-if="transactionType !== 'ถอนเครดิต'"
                >
                  <v-col cols="6" class="text-right px-4"> Bonus:</v-col>
                  <v-col cols="6" class="text-left px-4">
                    {{ approveData.bonus_amount }}</v-col
                  >
                </v-row>
                <v-row
                  no-gutters
                  class="text-sm px-4"
                  v-if="transactionType !== 'ถอนเครดิต'"
                >
                  <v-col cols="6" class="text-right px-4"> เวลา SMS:</v-col>
                  <v-col cols="6" class="text-left px-4">
                    {{ approveData.sms_time | dateTimeLocal }}</v-col
                  >
                </v-row>
                <v-row
                  no-gutters
                  class="text-sm px-4"
                  v-if="transactionType === 'ถอนเครดิต'"
                >
                  <v-col cols="6" class="text-right px-4">เลขบัญชี:</v-col>
                  <v-col cols="6" class="text-left px-4">
                    {{ approveData.member.account_number }}</v-col
                  >
                </v-row>
                <v-row align="center" v-if="transactionType == 'ถอนเครดิต'">
                  <v-col cols="6" class="text-right px-4" >การถอนโดยใช้ API : </v-col>
                  <v-col cols="6">
                    <v-switch v-model="withdrawAPI"></v-switch>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>
            <p class="text-sm font-light">
              คุณต้องการที่จะยืนยันการ{{ transactionType }}รายการนี้ หรือไม่?
            </p>
          </div>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="12" class="text-right">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="approveDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onApprove">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "./../../utils/role";
export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    transactionType: {
      type: String,
      default: ""
    },
    bonusItems: {
      type: [Array, Object],
      default: () => null
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      bonus: null,
      deleteValue: [],
      pageSizes: [10, 25, 50, 100],
      confirmDeleteDialog: false,
      approveDialog: false,
      approveData: null,
      roleMenu,
      roleOptions,
      withdrawAPI: true
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  methods: {
    approveItem(value) {
      this.approveData = value;
      if (this.approveData) this.approveDialog = true;
    },
    onApprove() {
      this.$emit("approveItem", {...this.approveData, withdrawWithAPI: this.withdrawAPI});
      this.approveDialog = false;
    },
    deleteItem(value) {
      this.confirmDeleteDialog = true;
      this.deleteValue = value;
    },
    comfirmDelete() {
      this.$emit("deleteItem", this.deleteValue);
      this.confirmDeleteDialog = false;
    },
    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
