<template>
  <div class="px-5">
    <v-row class="my-6" v-if="transferData">
      <v-col>
        <h1 class="font-bold text-xl text-white">
          Username : {{ transferData.username }} {{ title }}
        </h1>
      </v-col>
      <v-col class="text-right">
        <h1 class="font-bold text-xl text-white ">
          เครดิต :{{ transferData.credit }}
        </h1>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h1 class="text-white font-bold text-lg pb-2 my-2">ผู้ให้บริการ</h1>

          <v-row>
            <v-col
              cols="12"
              lg="6"
              sm="6"
              md="6"
              v-for="(item, index) in providers.memberProviders"
              :key="index"
            >
              <v-card
                color="#4E00AE"
                class="lg:my-2"
                dark
                flat
                @click="setProvider(item.id)"
              >
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <p class="ma-0 pa-0 font-bold text-lg">
                        {{ item.provider.description }}
                      </p>
                      <p class="ma-0 pa-0">
                        {{ item.provider_username }}
                      </p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-icon
                        color="error"
                        v-if="transferForm.memberProviderId !== item.id"
                      >
                        mdi mdi-plus-circle
                      </v-icon>

                      <v-icon color="success" v-else>
                        mdi mdi-check-circle
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
          <p class="ma-0 pa-0 text-white font-light text-sm">จำนวน</p>
          <v-text-field
            dark
            :rules="nameRules"
            required
            v-model="transferForm.amount"
            v-bind="textFieldProp"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
          <p class="ma-0 pa-0 text-white font-light text-sm">โบนัส</p>
          <v-combobox
            v-model="transferForm.bonusId"
            :items="bonusItems"
            cache-items
            item-value="id"
            color="#fff"
            dark
            clearable
            v-bind="textFieldProp"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title> ค้นหาโบนัส </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attr, on, item, selected }">
              <div v-bind="attr" :input-value="selected" v-on="on">
                <span v-text="item.title"></span>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="12" class="px-2 py-0">
          <p class="ma-0 pa-0 text-white font-light text-sm">รายละเอียด</p>
          <v-textarea
            dark
            rows="2"
            v-model="transferForm.description"
            v-bind="textFieldProp"
          ></v-textarea>
        </v-col>

        <!-- <v-col cols="12" class="px-5 py-0">
          <v-switch
            label="API"
            color="success"
            v-model="transferForm.use_api"
            dark
          ></v-switch>
        </v-col> -->
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" lg="6" sm="6" md="6">
        <v-btn
          block
          large
          dark
          outlined
          depressed
          @click="onSubmit"
          class="focus:outline-none border-2 rounded-lg"
        >
          <template v-slot:default>
            <span class="text-bold text-lg">บันทึก</span>
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar v-model="error" :vertical="true" top>
      <h1 class="text-lg font-bold py-2">ผิดพลาด</h1>
      <p>
        สมาชิก {{ transferData.username }} มีเครดิตใน
        {{ providerName(transferForm.memberProviderId) }} ไม่เพียงพอ
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- provider error -->
    <v-snackbar v-model="providerError" :vertical="true" top>
      <h1 class="text-lg font-bold py-2">ผิดพลาด</h1>
      <p>
        กรุณาเลือกผู้ให้บริการ
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="providerError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    transferData: {
      type: [Array, Object],
      default: () => []
    },
    providers: {
      type: [Array, Object],
      default: () => {}
    },
    bonusItems: {
      type: Array
    },
    transferForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      valid: false,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      error: false,
      providerError: false,
      title: ""
    };
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        // Convert string to number
        const amount = parseFloat(this.transferForm.amount);
        const credit = parseFloat(this.transferData.credit);

        const memberProviderId = this.transferForm.memberProviderId;

        // Condition credit member
        if (amount <= credit) {
          this.transferForm.amount = amount;
          this.transferForm.memberId = this.transferData.id;
          this.transferForm.transaction_type = "transferin";

          // Member provider is not empty
          if (!memberProviderId) {
            this.providerError = true;
          } else {
            this.$emit("onSubmitTransferIn", this.transferForm);
          }
        } else {
          // Show dialog error
          this.providerError = !memberProviderId;
          this.error = this.$refs.form.validate() && memberProviderId;
        }
      }
    },
    setProvider(id) {
      this.transferForm.memberProviderId = id;
    },
    providerName(memberProviderId) {
      let providerName;
      if (this.providers && memberProviderId) {
        providerName = this.providers.memberProviders.find(
          ({ id }) => id === memberProviderId
        );

        providerName = providerName.provider.name;
      }

      return providerName;
    }
  }
};
</script>
