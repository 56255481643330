<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="ค้นหา"
                        v-model="params.search"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-expansion-panels class="my-4" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >ค้นหาเพิ่มเติม</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="ชื่อแมทช์"
                              v-model="params.match_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="รายละเอียดแมทช์"
                              v-model="params.match_description"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Invite by username"
                              v-model="params.member_invite_by_username"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Member invite by name"
                              v-model="params.member_invite_by_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Username"
                              v-model="params.member_username"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="ชื่อสมาชิก"
                              v-model="params.member_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-select
                              label="Match สถานะ"
                              v-model="params.match_status"
                              outlined
                              dense
                              hide-details
                              :items="filterStatus"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-dialog
                              ref="matchStatusDialog"
                              v-model="matchStatusDialog"
                              :return-value.sync="params.match_date"
                              persistent
                              max-width="350"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="params.match_date"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  dense
                                  hide-details
                                  outlined
                                  v-bind="{ attrs }"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="params.match_date"
                                scrollable
                                locale="th"
                                width="100%"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="matchStatusDialog = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.matchStatusDialog.save(
                                      params.match_date
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn color="purple" block dark @click="search">
                              <v-icon> mdi mdi-magnify</v-icon> ค้นหา
                            </v-btn>
                          </v-col>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn
                              elevation="2"
                              color="purple"
                              block
                              outlined
                              class="mx-1"
                              @click="clear"
                            >
                              <v-icon>mdi-sync</v-icon>
                              เคลียร์
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <PartnerDataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import PartnerDataTable from "@/components/tables/PartnerDatatable";

export default {
  data() {
    return {
      statusItems: ["pending", "open", "close", "cancel", "done"],
      headers: [
        { text: "ID", value: "match_id" },
        { text: "ชื่อแมทซ์", value: "match_name" },
        { text: "ชื่อสมาชิก", value: "member_name" },
        { text: "Username", value: "member_username" },
        { text: "แนะนำโดย", value: "invite_by_name" },
        { text: "ยอดรวม", value: "total_betting_result" }
      ],
      meta: {},
      desserts: [],
      filterStatus: ["pending", "open", "close", "cancel", "done"],
      matchStatusDialog: false,
      params: {
        limit: 10,
        page: 1,
        search: null,
        match_name: "",
        match_status: "",
        match_date: "",
        member_invite_by_username: "",
        member_invite_by_name: "",
        member_username: "",
        member_name: ""
      }
    };
  },

  watch: {
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },

    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findAll(query) {
      try {
        const { data, status } = await HorseService.findAllPartner({
          ...query,
          member_invite_by_id: this.$route.query.member,
          match_date: this.$route.query.date
        });
        this.params.match_date = this.$route.query.date;
        
        if (status === 200) {
          this.desserts = data.items;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.findAll(this.params);
    });
  },
  components: {
    PartnerDataTable
  }
};
</script>
