<template>
  <v-dialog v-model="approveMessageDialog" width="80%">
    <v-card v-if="memberData">
      <v-card-text>
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              fab
              depressed
              color="transparent"
              class="focus:outline-none"
              small
              @click="approveMessageDialog = false"
              ><v-icon> mdi mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col cols="4">
            <v-sheet class="my-4 text-center">
              <v-row no-gutters justify="center">
                <v-col cols="10">
                  <v-avatar
                    size="100"
                    :color="memberData.member.bank | bankColor"
                    class="my-4 pa-2"
                  >
                    <v-img
                      :src="memberData.member.bank | bankImage"
                      contain
                    ></v-img>
                  </v-avatar>
                  <v-row no-gutters class="font-bold text-lg px-4">
                    <v-col cols="6" class="text-right px-4">Username:</v-col>
                    <v-col cols="6" class="text-left px-4">
                      {{ memberData.member.username }}</v-col
                    >
                  </v-row>
                  <v-row no-gutters class="text-lg px-4 font-bold">
                    <v-col cols="6" class="text-right px-4">ชื่อ:</v-col>
                    <v-col cols="6" class="text-left px-4">
                      {{ memberData.member.name }}</v-col
                    >
                  </v-row>
                  <v-row no-gutters class="font-bold text-lg px-4">
                    <v-col cols="6" class="text-right px-4">จำนวน:</v-col>
                    <v-col cols="6" class="text-left px-4 text-red-700">
                      {{ memberData.amount }}</v-col
                    >
                  </v-row>

                  <v-row no-gutters class="text-lg px-4">
                    <v-col cols="6" class="text-right px-4 font-bold"
                      >เลขบัญชี:</v-col
                    >
                    <v-col cols="6" class="text-left px-4 font-bold ">
                      {{ memberData.member.account_number }}</v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col cols="8">
            <v-card flat outlined>
              <v-card-text>
                <div id="div1" ref="copy">
                  <p class="ma-0 pa-0">
                    แจ้งถอนโดย : {{ memberData.member.username }}
                  </p>
                  <p class="ma-0 pa-0">
                    ยอดก่อนถอน : {{ memberData.credit_before }}
                  </p>
                  <p class="ma-0 pa-0">แจ้งถอน : {{ memberData.amount }}</p>
                  <p class="ma-0 pa-0">
                    ยอดคงเหลือ : {{ memberData.credit_after }}
                  </p>
                  <p class="ma-0 pa-0">
                    ทำรายการถอนให้เรียบร้อยแล้วนะคะ ขอบคุณที่ให้ความไว้วางใจ
                    เลือกใช้บริการกับ betdeep นะคะพี่
                  </p>
                </div>
              </v-card-text>
            </v-card>

            <div class="my-2">
              <v-btn
                @click="CopyToClipboard('div1')"
                depressed
                small
                color="error"
                >Copy</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    memberData: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    CopyToClipboard(containerid) {
      window.getSelection().empty();
      if (document.selection) {
        let range = document.body.createTextRange();
        range.moveToElementText(document.getElementById(containerid));
        range.select().createTextRange();
        document.execCommand("copy");
      } else if (window.getSelection) {
        let range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().addRange(range);
        document.execCommand("copy");

        // alert("Text has been copied, now paste in the text-area");
      }

      this.$emit("onClose", false);
    }
  },
  computed: {
    approveMessageDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("onClose", value);
      }
    }
  }
};
</script>
