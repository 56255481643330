<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="permission(roleOptions.delete) && checkScope('delete')"
        >
          mdi-delete
        </v-icon>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="redirectDetail(item)"> mdi-eye </v-icon>
        <v-icon class="mr-2" @click="createIbc(item)" color="success">
          mdi-bank
        </v-icon>
        <v-btn x-small color="info" depressed @click="redirectCommission(item)"
          >คอมมิสชั่น</v-btn
        >
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.invite_by_name`]="{ item }">
        {{ item.invite_by_username }} : {{ item.invite_by_name }}
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "./../../utils/role";

export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    },
    scope: {
      type: Array,
      default: () => ["edit", "delete"]
    }
  },
  data() {
    return {
      deleteValue: [],
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  methods: {
    redirectDetail(values) {
      this.$router.push({
        path: "/partner/detail",
        query: {
          member: values.id,
          date: values.date
        }
      });
    },
    redirectCommission(values) {
      this.$router.push({
        path: "/partner/commission",
        query: {
          date: values.date,
          member: values.id
        }
      });
    },
    createIbc(values) {
      this.$emit("onCreateIbc", values);
    },
    checkScope(value) {
      return !!this.scope.find(item => item === value);
    },
    //  editItem(value) {
    //    this.$emit("editItem", value);
    //  },
    //  deleteItem(value) {
    //    this.confirmDeleteDialog = true;
    //    this.deleteValue = value;
    //  },
    //  comfirmDelete() {
    //    this.$emit("deleteItem", this.deleteValue);
    //    this.confirmDeleteDialog = false;
    //  },
    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
