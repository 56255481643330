import { backendAPI } from "./../utils/axios";

const prefix = "/member-provider/";

class MemberProviderService {
  async findAll(params) {
    const response = await backendAPI.get(`${prefix}`, { params });

    return response;
  }

  async find(id) {
    const response = await backendAPI.get(`${prefix}${id}`);

    return response;
  }
  async findMember(id) {
    const response = await backendAPI.get(`${prefix}member/${id}`);

    return response;
  }

  async login(data) {
    const response = await backendAPI.post(`${prefix}login`, data);

    return response;
  }

  async updatePassword(id, data) {
    const response = await backendAPI.put(
      `${prefix}update-password/${id}`,
      data
    );

    return response;
  }

  async create(data) {
    const response = await backendAPI.post(`${prefix}`, data);

    return response;
  }

  async register(data) {
    const response = await backendAPI.post(`${prefix}register`, data);

    return response;
  }

  async update(id, data) {
    const response = await backendAPI.put(`${prefix}${id}`, data);

    return response;
  }
  async delete(id) {
    const response = await backendAPI.delete(`${prefix}${id}`);

    return response;
  }
}

export default new MemberProviderService();
