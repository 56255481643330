<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="9" sm="9" md="9">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="ค้นหา"
                    v-model="params.search"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-expansion-panels class="my-4" flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >ค้นหาเพิ่มเติม</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row align="center">
                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="รหัสธุรกรรม"
                          v-model="params.id"
                          outlined
                          type="number"
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="Username"
                          v-model="params.username"
                          outlined
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="ชื่อ"
                          v-model="params.name"
                          outlined
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="เลขบัญชี"
                          v-model="params.account_number"
                          outlined
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="เบอร์โทรศัพท์"
                          v-model="params.tel"
                          outlined
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="Line"
                          v-model="params.line_id"
                          outlined
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-text-field
                          label="Sms name"
                          v-model="params.sms_name"
                          outlined
                          dense
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-select
                          label="สถานะ"
                          v-model="params.status"
                          outlined
                          dense
                          hide-details
                          :items="filterStatus"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-select
                          label="Type of create"
                          v-model="params.type_of_created"
                          outlined
                          dense
                          hide-details
                          :items="filterTypeOfCreate"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="4" sn="4" md="4">
                        <v-select
                          label="Active"
                          v-model="params.is_active"
                          outlined
                          dense
                          hide-details
                          :items="isActive"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" lg="4" md="4" sm="4">
                        <v-btn color="purple" block dark @click="search">
                          <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                        >
                      </v-col>
                      <v-col cols="6" lg="4" md="4" sm="4">
                        <v-btn
                          elevation="2"
                          color="purple"
                          block
                          outlined
                          class="mx-1"
                          @click="clear"
                        >
                          <v-icon>mdi-sync</v-icon>
                          เคลียร์
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="auto" sm="auto" md="auto">
            <v-btn
              block
              color="#51adcf"
              dark
              class="font-weight-bold"
              @click="drawer = true"
              v-if="$actionPermisstion($roleMenu.member, $roleOptions.create)"
            >
              <v-icon>mdi mdi-plus</v-icon>
              เพิ่มสมาชิก</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="my-4">
      <v-card-text>
        <TransferDataTable
          :headers="headers"
          :desserts="desserts"
          :meta="meta"
          @deleteItem="deleteItem"
          @editItem="editItem"
          @transfer="transfer"
          @selectPage="selectPage"
          @setCurrentPage="setCurrentPage"
          @onCreateDeposit="depositCreate"
          @onCreateWithDraw="withdrawCreate"
          @updateCredit="updateCredit"
          role="member"
        />
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      color="#51adcf"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar
            flat
            dark
            dense
            color="transparent"
            width="100%"
            max-height="50px"
          >
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span v-if="!editID">สร้างสมาชิก</span>
            <span v-else>แก้ไขสมาชิก</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form
                class="ma-0 pa-0"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row no-gutters>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">ชื่อ</p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-model="memberForm.first_name"
                      v-bind="textFieldProp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      นามสกุล
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-model="memberForm.last_name"
                      v-bind="textFieldProp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      แนะนำโดย
                    </p>
                    <v-combobox
                      :readonly="editID ? true : false"
                      v-model="memberForm.inviteId"
                      :items="memberItems"
                      :search-input.sync="memberSearch"
                      item-value="id"
                      item-text="username"
                      color="#fff"
                      dark
                      :clearable="!editID ? true : false"
                      :update:search-input.sync="memberSearch"
                      v-bind="textFieldProp"
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{ item.username }} : {{ item.name }} </span>
                      </template>

                      <template v-slot:item="{ attrs, item }">
                        <span>{{ item.username }} : {{ item.name }}</span>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      เบอร์โทรศัพท์
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-model="memberForm.tel"
                      v-bind="textFieldProp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Line ID
                    </p>
                    <v-text-field
                      dark
                      v-model="memberForm.line_id"
                      v-bind="textFieldProp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      ชื่อธนาคาร
                    </p>

                    <v-combobox
                      v-model="memberForm.bank"
                      :items="bankItems"
                      cache-items
                      item-text="name"
                      item-value="name"
                      color="#fff"
                      :search-input.sync="searchBank"
                      dark
                      :rules="nameRules"
                      required
                      clearable
                      v-bind="textFieldProp"
                    >
                      <template v-slot:item="{ item }">
                        <v-avatar :color="item.color" size="35">
                          <v-img
                            :src="item.image"
                            width="20"
                            height="20"
                            contain
                          >
                          </v-img>
                        </v-avatar>
                        <span class="px-2"
                          >{{ item.nice_name }} ({{ item.name }})</span
                        >
                      </template>

                      <template v-slot:selection="{ item }">
                        <v-avatar :color="item.color" size="30">
                          <v-img
                            :src="item.image"
                            width="20"
                            height="20"
                            contain
                          >
                          </v-img>
                        </v-avatar>
                        <span class="px-2">{{ item.nice_name }}</span>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      เลขบัญชี
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-model="memberForm.account_number"
                      v-bind="textFieldProp"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      SMS Name
                    </p>
                    <v-text-field
                      dark
                      v-model="memberForm.sms_name"
                      v-bind="textFieldProp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                    <p
                      class="ma-0 pa-0 text-white font-light text-sm"
                      v-if="!editID"
                    >
                      จำนวนเงินฝาก
                    </p>
                    <p v-else class="ma-0 pa-0 text-white font-light text-sm">
                      จำนวนเครดิตปัจจุบัน
                    </p>
                    <v-text-field
                      dark
                      v-model="memberForm.credit"
                      v-bind="textFieldProp"
                      :disabled="editID"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    sm="6"
                    md="6"
                    class="px-2 py-0"
                    v-if="!editID"
                  >
                    <p class="ma-0 pa-0 text-white font-light text-sm">โบนัส</p>

                    <v-combobox
                      v-model="memberForm.bonusId"
                      :items="bonusItems"
                      cache-items
                      item-value="id"
                      item-text="title"
                      color="#fff"
                      :search-input.sync="bonusSearch"
                      dark
                      clearable
                      v-bind="textFieldProp"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title> ค้นหาโบนัส </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ attr, on, item, selected }">
                        <div v-bind="attr" :input-value="selected" v-on="on">
                          <span v-text="item.title"></span>
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row justify="start" no-gutters>
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-4">
                    <v-btn
                      block
                      large
                      dark
                      outlined
                      depressed
                      @click="confirmCreateDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    sm="6"
                    md="6"
                    class="px-4"
                    v-if="editID"
                  >
                    <v-btn
                      block
                      large
                      dark
                      depressed
                      color="primary"
                      @click="forgotPasswordDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">แก้ไขรหัสผ่าน</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="editID">
                  <v-col cols="12">
                    <h1 class="text-white font-bold text-lg pb-2 my-2">
                      ผู้ให้บริการ
                    </h1>
                    <v-card
                      class="my-2"
                      dark
                      flat
                      v-for="(item, index) in providerItems"
                      :color="!checkProvider(item.id) ? '#4E00AE' : '#5c00cc'"
                      :disabled="checkProvider(item.id)"
                      :key="index"
                      @click="selected = item.id"
                    >
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col>
                            <p class="ma-0 pa-0 font-bold text-lg">
                              {{ item.name }}
                            </p>
                            <p class="ma-0 pa-0">{{ item.description }}</p>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col class="text-right">
                            <v-icon
                              v-if="!checkProvider(item.id)"
                              color="error"
                            >
                              mdi mdi-plus-circle
                            </v-icon>

                            <v-icon v-else color="success">
                              mdi mdi-check-circle
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span v-if="!editID">เพิ่มสมาชิก</span>
              <span v-else>แก้ไขสมาชิก</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog v-model="forgotPasswordDialog" persistent max-width="500">
        <v-card tile flat>
          <v-form class="ma-0 pa-0" ref="forgotPasswordForm" lazy-validation>
            <v-card-text>
              <h1 class="text-xl font-bold mt-4 mb-2">
                <span>แก้ไขรหัสผ่าน</span>
              </h1>
              <v-row>
                <v-col cols="12">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    รหัสผ่านใหม่
                  </p>
                  <v-text-field
                    type="password"
                    placeholder="รหัสผ่านใหม่"
                    :rules="nameRules"
                    outlined
                    v-model="newPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-row no-gutters justify="end">
              <v-col cols="6" class="text-right pa-4">
                <v-btn
                  color="error"
                  depressed
                  text
                  tile
                  @click="forgotPasswordDialog = false"
                >
                  ยกเลิก
                </v-btn>

                <v-btn
                  color="success"
                  text
                  depressed
                  tile
                  @click="forgotPassword"
                >
                  ยืนยัน
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>

    <v-dialog v-model="updateCreditDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">อัพเดทเครดิต</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Credit"
                  type="number"
                  v-model="credit"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="updateCreditDialog = false">
            ยกเลิก
          </v-btn>
          <v-btn
            color="success"
            text
            depressed
            tile
            @click="handleUpdateCredit"
          >
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TransferDrawer
      :drawer="transferDrawer"
      :providers="memberProviderItems"
      :transferData="transferData"
      :bonus="bonusItems"
      @onClose="value => (transferDrawer = value)"
    />

    <DepositDrawer
      :drawer="depositDialog"
      :createForm="depositForm"
      :bonusItems="bonusItems"
      :memberItems="memberItems"
      @onClose="value => (depositDialog = value)"
      @onSubmitDeposit="onSubmitDeposit"
    />

    <WithdrawDrawer
      :drawer="withdrawDialog"
      :createForm="withdrawForm"
      :memberItems="memberItems"
      @onClose="value => (withdrawDialog = value)"
      @onSubmitWithdraw="onSubmitWithdraw"
    />
  </div>
</template>

<script>
import TransferDataTable from "@/components/tables/TransferDataTable";

import MemberService from "./../../services/member.service";
import MemberProviderService from "./../../services/member-provider.service";
import ProviderService from "./../../services/provider.service";
import TransactionService from "./../../services/transaction.service";

import TransferDrawer from "./components/drawers/TrnasferDrawer";
import DepositDrawer from "./components/drawers/DepositDrawer";
import WithdrawDrawer from "./components/drawers/WithdrawDrawer";
import bankService from "../../services/bank.service";
import bonusService from "./../../services/bonus.service";
import { mapActions } from "vuex";
import omit from "lodash/omit";
import moment from "moment-timezone";

export default {
  data() {
    return {
      valid: false,
      editID: null,
      confirmCreateDialog: false,
      drawer: false,
      transferDrawer: false,
      bonus: null,
      bonusSearch: null,
      bonusItems: [],
      bankItems: [],
      memberItems: [],
      searchBank: null,
      isLoading: false,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      memberSearch: null,
      newPassword: "",
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#0278ae"
      },
      params: {
        limit: 10,
        page: 1,
        search: null,
        id: null,
        name: null,
        username: null,
        account_number: null,
        tel: null,
        line_id: null,
        sms_name: null,
        status: null,
        is_active: null,
        type_of_created: null
      },
      searchOption: null,
      searchValue: null,
      desserts: [],
      meta: {},
      headers: [
        { text: "รหัสธุรกรรม", value: "id" },
        // { text: "Avatar", value: "avatar" },
        { text: "Username", value: "username" },
        { text: "ชื่อ", value: "name" },
        { text: "ธนาคาร", value: "bank" },
        { text: "เลขบัญชี", value: "account_number" },
        { text: "เบอร์โทรศัพท์", value: "tel" },
        { text: "Line ID", value: "line_id" },
        { text: "เครดิต", value: "credit" },
        { text: "Club Point", value: "club_point" },
        { text: "Invite", value: "invite.username" },
        { text: "สถานะ", value: "status" },
        { text: "วันที่สมัคร", value: "created_at" },
        { text: "Actions", value: "actions" }
      ],
      memberForm: {
        first_name: "",
        last_name: "",
        bank: null,
        bonusId: null,
        account_number: null,
        tel: null,
        line_id: "",
        avatar: "string",
        sms_name: null,
        credit: 0,
        club_point: 0,
        invite_point: 0,
        status: "pending",
        is_active: true,
        type_of_created: "user",
        password: "",
        inviteId: null
      },
      selected: null,
      disabledProvider: [],
      memberEditData: {},
      providerItems: [],
      memberProviderItems: [],
      transferData: {},
      depositDialog: false,
      withdrawDialog: false,
      withdrawForm: {
        memberId: null,
        amount: 0,
        description: null,
        status: "pending",
        transaction_type: "withdraw",
        type_of_created: "user"
      },
      depositForm: {
        memberId: null,
        amount: 0,
        bonusId: null,
        description: null,
        sms_time: new Date(),
        status: "pending",
        transaction_type: "deposit",
        type_of_created: "user",
        is_register: false,
        use_api: false,
        inviteId: null,
        update_credit: true
      },
      filterTypeOfCreate: ["user", "member", "system"],
      filterStatus: ["pending", "done", "failure"],
      isActive: [true, false],
      panel: false,
      forgotPasswordDialog: false,
      updateCreditDialog: false,
      credit: 0
    };
  },

  methods: {
    ...mapActions({
      alert: "notification/show"
    }),
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.params.page = 1;

        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    resetForm() {
      this.bonus = null;
      this.memberForm = this.$options.data.call(this).memberForm;
    },
    async findAll(options) {
      const response = await MemberService.findAll(options);

      if (response.status === 200) {
        this.desserts = response.data.items;
        this.meta = response.data.meta;
      }
    },
    async findMemberList() {
      const memberData = await MemberService.list();
      if (memberData.status === 200) {
        this.memberItems = memberData.data;
      }
    },
    async getMemberProvider(id) {
      const response = await MemberProviderService.findMember(id);
      const disabled = [];
      if (response.status === 200) {
        this.memberProviderItems = response.data;

        response.data.memberProviders.map(item =>
          disabled.push(item.provider.id)
        );

        this.disabledProvider = disabled;
        this.memberEditData = response.data;
      }
    },
    async getProvider() {
      const response = await ProviderService.list();

      if (response.status === 200) {
        this.providerItems = response.data;
      }
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.memberForm.credit = parseInt(this.memberForm.credit);
        this.memberForm.club_point = parseInt(this.memberForm.club_point);
        this.memberForm.password = `bd${this.memberForm.tel}`;

        this.memberForm.bonusId = this.memberForm?.bonusId?.id;
        this.memberForm.bank = this.memberForm.bank.name;
        this.memberForm.inviteId = this.memberForm?.inviteId?.id;

        this.memberForm.first_name = this.memberForm.first_name.replace(
          /\s+/g,
          ""
        );
        this.memberForm.last_name = this.memberForm.last_name.replace(
          /\s+/g,
          ""
        );
        this.memberForm.account_number = this.memberForm.account_number.replace(
          /\s+/g,
          ""
        );
        if (!this.editID) {
          const { data, status } = await MemberService.create(this.memberForm);

          if (status === 201 && data.status !== "duplicate") {
            this.drawer = false;
            this.confirmCreateDialog = false;
            this.$refs.form.resetValidation();
            this.findAll(this.params);
          } else {
            this.confirmCreateDialog = false;
            this.$store.dispatch("loader/errorShow");
            this.$store.dispatch("loader/setError", data.message);
          }
        } else {
          this.memberForm = omit(this.memberForm, "status");
          this.memberForm = omit(this.memberForm, "password");

          const updated = await MemberService.update(
            this.editID,
            this.memberForm
          );
          if (updated.status === 200) {
            this.drawer = false;
            this.confirmCreateDialog = false;
            this.$refs.form.resetValidation();
            this.findAll(this.params);
          }
        }
      } else {
        this.confirmCreateDialog = false;
      }
    },
    editItem(value) {
      if (value) {
        this.editID = value.id;
        this.memberForm = this.lodash.cloneDeep(value);
        const name = this.memberForm.name.split("  ");
        this.memberForm.first_name = name[0];
        this.memberForm.last_name = name[1];
        this.memberForm.inviteId = value.invite;
        this.memberForm.bank = this.bankItems.find(
          ({ name }) => name === this.memberForm.bank
        );
        this.drawer = true;
      }
    },
    async handleUpdateCredit() {
      try {
        const { status } = await TransactionService.transactionUpdateCredit({
          memberId: this.editID,
          amount: Number(this.credit)
        });

        if (status == 201) {
          this.alert({ type: "success", text: "อัพเดทเครดิตเสร็จสิ้น" });
          this.editID = null;
          this.updateCreditDialog = false;
          this.credit = 0;
          this.findAll(this.params);
        } else {
          this.alert({
            type: "error",
            text: "อัพเดทเครดิตไม่สำเร็จกรุณาลองใหม่อีกครัง"
          });
        }
      } catch (error) {
        console.log(error);
        this.alert({
          type: "error",
          text: "อัพเดทเครดิตไม่สำเร็จกรุณาลองใหม่อีกครัง"
        });
      }
    },
    updateCredit(value) {
      if (value) {
        this.editID = value.id;
        this.updateCreditDialog = true;
        this.credit = value.credit;
        this.memberForm = this.lodash.cloneDeep(value);
      }
    },
    async deleteItem(value) {
      if (value) {
        const { id } = value;

        const deleted = await MemberService.delete(id);
        if (deleted.status === 200) {
          this.drawer = false;
          this.confirmCreateDialog = false;
          this.resetForm();

          this.findAll(this.params);
        }
      }
    },
    transfer(value) {
      this.transferDrawer = true;
      this.editID = value.id;
      this.transferData = value;

      // this.findAllBonus();
      this.getMemberProvider(this.editID);
    },
    async findAllBank(name) {
      if (this.bankItems.length > 0) return;

      const bankData = await bankService.list(name);

      if (bankData.status !== 200) return false;
      this.bankItems = bankData.data;
    },

    async findAllBonus(title) {
      const bonusData = await bonusService.list(title);

      if (bonusData.status !== 200) return false;
      this.bonusItems = bonusData.data;
    },
    checkProvider(id) {
      let status;
      const provider = this.disabledProvider.find(item => item === id);

      // const connectProviderStatus =
      //   this.memberEditData.memberProviders &&
      //   this.memberEditData.memberProviders.find(
      //     item => item.provider.id === id
      //   );
      status = provider ? true : false;

      return status;
    },
    async onSubmitDeposit(values) {
      const deposit = await TransactionService.deposit(values);

      if (deposit.status === 201) {
        // Socket
        const room = deposit.data.member.id.toString();

        this.$socket.emit("setSocketDeposit", room);
        this.$socket.emit("setSocketNotificationDeposit", room);
        this.$socket.emit("setSocketNotificationAdminApproveDeposit");
        //End Socket

        this.alert({ type: "success", text: "เติมเครดิตเรียบร้อย" });
        this.depositForm = this.$options.data.call(this).depositForm;
        this.findAll(this.params);
      } else {
        this.alert({ type: "error", text: "ไม่สามารถแก้ไขเครดิตได้" });
      }
    },
    async onSubmitWithdraw(values) {
      const withdraw = await TransactionService.withdraw(values);
      // Socket
      const room = withdraw.data.member.id.toString();

      this.$socket.emit("setSocketWithdraw", room);
      this.$socket.emit("setSocketNotificationWithdraw", room);
      this.$socket.emit("setSocketNotificationAdminApproveWithdraw");
      //End Socket

      this.alert({ type: "success", text: "ถอนเครดิตเรียบร้อย" });
      this.withdrawForm = this.$options.data.call(this).withdrawForm;
      if (withdraw.status === 201) {
        this.findAll(this.params);
      } else {
        this.alert({ type: "error", text: "ไม่สามารถแก้ไขถอนดิตได้" });
      }
    },
    depositCreate(value) {
      this.depositForm.memberId = value;
      this.depositDialog = true;
      this.findAllBonus();
    },
    withdrawCreate(value) {
      this.withdrawForm.memberId = value;
      this.withdrawDialog = true;
    },
    smsDate(value) {
      const date = moment(value).format("YYYY-MM-D H:mm");

      return date;
    },
    async forgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        const { data, status } = await MemberService.forgotPassword(
          this.editID,
          this.newPassword
        );

        status === 200
          ? this.alert({ type: "success", text: "แก้ไขรหัสผ่านเรียบร้อย" })
          : this.alert({
              type: "error",
              text: "ไม่แก้ไขรหัสผ่านได้ กรุณาตรวจสอบข้อมูลอีกครั้ง"
            });
        this.newPassword = "";
        this.forgotPasswordDialog = false;
        return data;
      }
    }
  },
  computed: {
    roles() {
      const user = JSON.parse(localStorage.getItem("user"));
      const role = JSON.parse(user.role);

      return role;
    }
  },
  watch: {
    drawer(value) {
      if (!value) {
        this.$refs.form.resetValidation();
        this.resetForm();
        this.drawer = false;
        this.editID = null;
      }

      this.newPassword = "";
      // this.$refs.forgotPasswordForm.reset();

      if (this.memberItems.length === 0) {
        this.findMemberList();
      }
      if (this.bankItems.length === 0) {
        this.findAllBank();
      }
    },
    depositDialog(value) {
      this.depositForm.sms_time = this.smsDate(new Date());
      if (!value) {
        this.memberItems = [];
      }
    },
    withdrawDialog(value) {
      if (!value) {
        this.memberItems = [];
      }
    },
    async memberSearch(username) {
      this.memberSearch = username;
      if (!username) return;

      const memberData = await MemberService.list(username);
      if (memberData.status === 200) {
        this.memberItems = memberData.data;
      }
    },
    bonusSearch(value) {
      if (!value) return;
      this.findAllBonus(value);
    },
    searchBank(value) {
      const bank = value ? value.toUpperCase() : "";
      this.findAllBank(bank);
    },
    editID(value) {
      if (!value) return;

      this.getProvider();
      this.getMemberProvider(value);
      this.findAllBonus(this.bonusParams);
    },
    selected(value) {
      if (!value) return;

      const data = {
        memberId: this.editID,
        providerId: value
      };
      MemberProviderService.register(data)
        .then(response => response.data.provider.id)
        .then(response => this.disabledProvider.push(response))
        .catch(() => {
          this.selected = null;
        });
    },
    transferDrawer(value) {
      if (value) {
        this.findAllBonus(this.bonusParams);
      } else {
        this.findAll(this.params);
      }
    },
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    },
    forgotPasswordDialog() {
      this.newPassword = "";
      // this.$refs.forgotPasswordForm.reset();
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.findAll(this.params);
      this.findAllBank(this.memberForm.bank);
      this.findMemberList();
      this.findAllBonus();
    });
  },
  components: {
    TransferDataTable,
    TransferDrawer,
    DepositDrawer,
    WithdrawDrawer
  }
};
</script>
