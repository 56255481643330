<template>
  <div class="px-5">
    <v-row class="my-6" v-if="transferData">
      <v-col>
        <h1 class="font-bold text-white text-xl">
          Username : {{ transferData.username }}
        </h1>
      </v-col>
      <v-col class="text-right">
        <h1 class="font-bold text-white text-xl">เครดิต :{{ credit }}</h1>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h1 class="text-white font-bold text-lg pb-2 my-2">
            ผู้ให้บริการ
          </h1>

          <v-row>
            <v-col
              cols="12"
              lg="6"
              sm="6"
              md="6"
              v-for="(item, index) in providers.memberProviders"
              :key="index"
            >
              <v-card
                color="#3b4178"
                class="lg:my-2"
                dark
                flat
                @click="checkCreditPrivider(item.provider.id, item.id)"
              >
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <p class="ma-0 pa-0 font-bold text-lg">
                        {{ item.provider.description }}
                      </p>
                      <p class="ma-0 pa-0">
                        {{ item.provider.description }}
                      </p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-icon
                        color="error"
                        v-if="transferForm.memberProviderId !== item.id"
                      >
                        mdi mdi-plus-circle
                      </v-icon>

                      <v-icon color="success" v-else>
                        mdi mdi-check-circle
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="px-2 py-0">
          <p class="ma-0 pa-0 text-white font-light text-sm">
            จำนวน
          </p>
          <v-text-field
            dark
            :rules="nameRules"
            required
            v-model="transferForm.amount"
            v-bind="textFieldProp"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="px-2 py-0">
          <p class="ma-0 pa-0 text-white font-light text-sm">
            รายละเอียด
          </p>
          <v-textarea
            dark
            rows="2"
            v-model="transferForm.description"
            v-bind="textFieldProp"
          ></v-textarea>
        </v-col>
        <!-- 
        <v-col cols="12" class="px-6 py-0">
          <v-switch
            label="API"
            color="success"
            v-model="transferForm.use_api"
            dark
          ></v-switch>
        </v-col> -->
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" lg="6" sm="6" md="6">
        <v-btn
          block
          large
          dark
          outlined
          depressed
          @click="onSubmit"
          class="focus:outline-none border-2 rounded-lg"
        >
          <template v-slot:default>
            <span class="text-bold text-lg">บันทึก</span>
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar v-model="error" :vertical="true" top>
      <h1 class="text-lg font-bold py-2">ผิดพลาด</h1>
      <p>
        สมาชิก {{ transferData.username }} มีเครดิตใน
        {{ providerName(transferForm.memberProviderId) }} ไม่เพียงพอ
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- provider error -->
    <v-snackbar v-model="providerError" :vertical="true" top>
      <h1 class="text-lg font-bold py-2">ผิดพลาด</h1>
      <p>
        กรุณาเลือกผู้ให้บริการ
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="providerError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import TransactionService from "./../../../../services/transaction.service";
export default {
  props: {
    transferData: {
      type: [Array, Object],
      default: () => {}
    },
    providers: {
      type: [Array, Object],
      default: () => []
    },
    transferForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#2a2e55"
      },
      credit: 0,
      error: false,
      providerError: false
    };
  },
  methods: {
    async checkCreditPrivider(id, memberProviderId) {
      const data = {
        memberId: this.transferData.id,
        providerId: id
      };
      const response = await TransactionService.checkCreditProvider(data);
      if (response.status === 201) {
        this.credit = response.data.balance | response.data.current_credit;
        this.transferForm.memberProviderId = memberProviderId;
      }
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        // Convert string to number
        const amount = parseFloat(this.transferForm.amount);
        const credit = parseFloat(this.credit);

        const memberProviderId = this.transferForm.memberProviderId;

        // Condition credit provider
        if (amount <= credit) {
          this.transferForm.amount = amount;
          this.transferForm.memberId = this.transferData.id;
          this.transferForm.transaction_type = "transferout";

          // Member provider is not empty
          if (memberProviderId) {
            this.$emit("onSubmitTransferOut", this.transferForm);
            this.clearForm();
          } else {
            this.providerError = true;
          }
        } else {
          // Show dialog errorr
          this.providerError = !memberProviderId;
          this.error = this.$refs.form.validate() && memberProviderId;
        }
      }
    },
    providerName(memberProviderId) {
      let providerName;
      if (this.providers && memberProviderId) {
        providerName = this.providers.memberProviders.find(
          ({ id }) => id === memberProviderId
        );

        providerName = providerName.provider.name;
      }

      return providerName;
    },
    clearForm() {
      this.credit = 0;
      this.error = false;
      this.providerError = false;
      this.transferForm.amount = 0;
      this.transferForm.memberId = null;
      this.transferForm.transaction_type = "transferin";
    }
  }
};
</script>
