import { backendAPI } from "./../utils/axios";

const prefix = "/member/";

class MemberService {
  async findAll(params) {
    const response = await backendAPI.get(`${prefix}`, { params });

    return response;
  }

  async find(id) {
    const response = await backendAPI.get(`${prefix}${id}`);

    return response;
  }

  async list(search) {
    const params = { search };
    const response = await backendAPI.get(`${prefix}list`, {
      params,
      showLoader: false
    });

    return response;
  }

  async create(data) {
    const response = await backendAPI.post(`${prefix}`, data);

    return response;
  }

  async update(id, data) {
    const response = await backendAPI.put(`${prefix}${id}`, data);

    return response;
  }

  async forgotPassword(id, password) {
    const response = await backendAPI.put(`${prefix}password/${id}`, {
      password
    });

    return response;
  }
  async delete(id) {
    const response = await backendAPI.delete(`${prefix}${id}`);

    return response;
  }
}

export default new MemberService();
