import { backendAPI } from "./../utils/axios";

const prefix = "/transaction/";

class TransactionService {
  async findAll(params) {
    const response = await backendAPI.get(`${prefix}`, { params });

    return response;
  }

  async find(id) {
    const response = await backendAPI.get(`${prefix}${id}`);

    return response;
  }
  async findAllDone(params) {
    const response = await backendAPI.get(`${prefix}findAllDone`, { params });

    return response;
  }

  async create(data) {
    const response = await backendAPI.post(`${prefix}`, data);

    return response;
  }

  async update(id, data) {
    const response = await backendAPI.put(`${prefix}${id}`, data);

    return response;
  }
  async delete(id) {
    const response = await backendAPI.delete(`${prefix}${id}`);

    return response;
  }
  async deposit(data) {
    const response = await backendAPI.post(`${prefix}deposit`, data);

    return response;
  }
  async findDepositPending(params) {
    const response = await backendAPI.get(`${prefix}deposit/pending`, {
      params
    });

    return response;
  }
  async findWithdrawPending(params) {
    const response = await backendAPI.get(`${prefix}withdraw/pending`, {
      params
    });

    return response;
  }
  async withdraw(data) {
    const response = await backendAPI.post(`${prefix}withdraw`, data);

    return response;
  }

  async approveWithDraw(id) {
    return await backendAPI.put(`${prefix}approve-withdraw/${id}`);
  }

  async approveDepositAuto(id, bonus) {
    return await backendAPI.put(`${prefix}approve-deposit-auto/${id}`, {
      bonusId: bonus
    });
  }

  async transactionIn(data) {
    const response = await backendAPI.post(`${prefix}transferin`, data);

    return response;
  }
  async transactionOut(data) {
    const response = await backendAPI.post(`${prefix}transferout`, data);

    return response;
  }
  async checkCreditProvider(data) {
    const response = await backendAPI.post(
      `${prefix}check-credit-provider`,
      data
    );

    return response;
  }
  async transactionUpdateCredit(data) {
    const response = await backendAPI.post(`${prefix}updateCredit`, data);

    return response;
  }

  async approveWithDrawAPI(id, data) {
    return await backendAPI.put(`${prefix}approve-withdraw/${id}`, data);
    // console.log(id, data)
  }
}

export default new TransactionService();
