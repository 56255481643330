<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="9" sm="9" md="9">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12" lg="8" sm="8" md="8"
                      ><v-text-field
                        outlined
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn color="purple" block dark>
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" lg="auto" sm="auto" md="auto">
                <v-btn
                  block
                  color="primary"
                  class="font-weight-bold"
                  @click="drawer = true"
                >
                  <v-icon>mdi mdi-plus</v-icon>
                  สร้างธนาคาร</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <DataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar flat dark dense color="transparent" max-height="50px">
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            สร้างธนาคาร
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form class="ma-0 pa-0" ref="form" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">ชื่อ</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="bankForm.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Official name
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="bankForm.official_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Nice name
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="bankForm.nice_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">สี</p>

                    <v-color-picker
                      dot-size="25"
                      mode="hexa"
                      v-model="bankForm.color"
                      swatches-max-height="200"
                    ></v-color-picker>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-6">
                    <v-btn
                      block
                      large
                      outlined
                      dark
                      @click="confirmCreateDialog = true"
                      depressed
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span v-if="!editID">สร้างธนาคาร</span>
              <span v-else>แก้ไขธนาคาร</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable";
import BankService from "./../../services/bank.service";

export default {
  data() {
    return {
      headers: [
        { text: "ชื่อ", value: "name" },
        { text: "Nice name", value: "nice_name" },
        { text: "Official name", value: "official_name" },
        { text: "Actions", value: "actions" }
      ],
      meta: {},
      desserts: [],
      params: {
        limit: 10,
        page: 1,
        search: null
      },
      drawer: false,
      confirmCreateDialog: false,
      editID: null,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      bankForm: {
        name: null,
        color: null,
        official_name: null,
        nice_name: null,
        image: " ",
        is_active: true
      }
    };
  },
  watch: {
    drawer(value) {
      if (!value) {
        this.editID = null;
        this.clearForm();
        this.confirmCreateDialog = false;
      }

      this.$refs.form.resetValidation();
    }
  },
  methods: {
    async findAll(options) {
      const response = await BankService.findAll(options);
      if (response.status === 200) {
        this.desserts = response.data.items;
        this.meta = response.data.meta;
      }
    },
    clearForm() {
      this.bankForm = {
        name: null,
        color: null,
        official_name: null,
        nice_name: null,
        image: " ",
        is_active: true
      };
    },
    search() {},
    clear() {},
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.bankForm.color = this.bankForm.color.hexa;
        if (!this.editID) {
          const created = await BankService.create(this.bankForm);
          if (created.status === 201) {
            this.drawer = false;
            this.findAll(this.params);
          }
        } else {
          const updated = await BankService.update(this.editID, this.bankForm);
          if (updated.status === 200) {
            this.drawer = false;
            this.findAll(this.params);
          }
        }
      }
    },
    editItem(value) {
      if (!value) return false;

      this.editID = value.id;
      this.bankForm = this.lodash.cloneDeep(value);
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const deleted = await BankService.delete(value.id);

      if (deleted.status === 200) {
        this.findAll(this.params);
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(value);
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  components: {
    DataTable
  }
};
</script>
