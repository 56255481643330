<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-dialog
                        ref="matchStatusDialog"
                        v-model="matchStatusDialog"
                        :return-value.sync="params.match_date"
                        persistent
                        max-width="350"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="params.match_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            dense
                            hide-details
                            label="ค้นหา"
                            outlined
                            v-bind="{ attrs }"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="params.match_date"
                          scrollable
                          locale="th"
                          width="100%"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="matchStatusDialog = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.matchStatusDialog.save(params.match_date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" lg="4" md="4" sm="4">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="4" md="4" sm="4">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <PartnerDateDatatable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import PartnerDateDatatable from "@/components/tables/PartnerDateDatatable";

// import union from "lodash/union";
export default {
  data() {
    return {
      headers: [
        { text: "#", value: "index" },
        { text: "วันที่", value: "date" },
        { text: "Actions", value: "actions" },
      ],
      matchStatusDialog: false,
      meta: {},
      desserts: [],

      params: {
        limit: 10,
        page: 1,
        search: null,
        match_date: "",
      },
    };
  },

  watch: {
    drawer(value) {
      !value && this.resetForm();
    },
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    },
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },

    resetForm() {
      this.formData = this.$options.data.call(this).formData;
      this.$refs.form.resetValidation();
      this.editID = null;
      this.editWinPlaceStatus = false;
      this.editPositionStatus = false;
    },

    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findAll(query) {
      try {
        const { data, status } = await HorseService.findAllMatchDate(query);

        if (status === 200) {
          this.desserts = data.items;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.findAll(this.params);
    });
  },

  components: {
    PartnerDateDatatable,
  },
};
</script>
