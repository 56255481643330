<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.sms_time`]="{ item }">
        <!-- <span v-if="item.sms_time">{{ item.sms_time | dateTimeSMSLocal }}</span> -->
        <span v-if="item.sms_time">{{ item.sms_time | dateTimeSMSLocal }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span v-if="role !== 'deposit'">
          {{ item.updated_at | dateTimeLocal }}
        </span>
        <span v-else-if="role === 'deposit' && item.update_by">
          {{ item.updated_at | dateTimeLocal }}
        </span>
      </template>

      <template v-slot:[`item.status_api_auto`]="{ item }">
        <span v-if="item.status_api_auto === true" style="color:blue; font-weight: bold;">
          ✔
        </span>
        <span v-else-if="item.status_api_auto === false" style="color:red">
          ❌
        </span>
      </template>
      
      <template v-slot:[`item.is_auto`]="{ item }">
        <span v-if="item.is_auto === true" style="color:green; font-weight: bold;">
          ✔
        </span>
        <span v-else-if="item.is_auto === false">
          ❌
        </span>
      </template>

      <template v-slot:[`item.transaction_type`]="{ item }">
        {{ item.transaction_type | formatThTransaction }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status | statusFilter }}
      </template>
      <!-- <template v-slot:[`item.amount`]="{ item }">
        <p class="text-green-600">{{ item.amount }}</p>
      </template> -->
      <template v-slot:[`item.is_active`]="{ item }">
        <span v-if="item.transaction_type !== 'updatecredit'" style="color:red; font-weight: bold;">
          {{ item.is_active | transactionStatus }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="copyItem(item)" v-if="item.status === 'done'">
          mdi-content-copy
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="permission(roleOptions.update)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          @click="deleteItem(item)"
          v-if="permission(roleOptions.delete) && role !== 'deposit'"
        >
          mdi-delete
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-else-if="
            permission(roleOptions.delete) &&
              role === 'deposit' &&
              item.is_active
          "
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">ลบ</h1>
          <p class="text-sm font-light">
            คุณต้องการที่จะลบรายการนี้ หรือไม่?
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmDeleteDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="comfirmDelete">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "./../../utils/role";
export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      deleteValue: [],
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  methods: {
    editItem(value) {
      this.$emit("editItem", value);
    },
    deleteItem(value) {
      this.confirmDeleteDialog = true;
      this.deleteValue = value;
    },
    copyItem(value) {
      this.$emit("copyItem", value);
    },
    comfirmDelete() {
      this.$emit("deleteItem", this.deleteValue);
      this.confirmDeleteDialog = false;
    },
    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
