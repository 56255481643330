<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="ค้นหา"
                        v-model="params.search"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-expansion-panels  class="my-4" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>ค้นหาเพิ่มเติม</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Amount"
                              v-model="params.amount"
                              outlined
                              type="number"
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Betting result"
                              v-model="params.betting_result"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Result"
                              v-model="params.result"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <!-- 
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="รหัสสมาชิก"
                              v-model="params.member_id"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col> -->

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Member username"
                              v-model="params.member_username"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Member name"
                              v-model="params.member_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Match name"
                              v-model="params.match_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-select
                              label="Match สถานะ"
                              v-model="params.match_status"
                              outlined
                              dense
                              hide-details
                              :items="filterStatus"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-dialog
                              ref="matchStatusDialog"
                              v-model="matchStatusDialog"
                              :return-value.sync="params.match_date"
                              persistent
                              max-width="350"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="params.match_date"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  dense
                                  hide-details
                                  outlined
                                  v-bind="{ attrs }"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="params.match_date"
                                scrollable
                                locale="th"
                                width="100%"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="matchStatusDialog = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.matchStatusDialog.save(params.match_date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn color="purple" block dark @click="search">
                              <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                            >
                          </v-col>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn
                              elevation="2"
                              color="purple"
                              block
                              outlined
                              class="mx-1"
                              @click="clear"
                            >
                              <v-icon>mdi-sync</v-icon>
                              เคลียร์
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <DataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      temporary
      class="h-fll bg-black bg-gradient-purple"
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar flat dark dense color="transparent" max-height="50px">
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">แก้ไขเบท</h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form class="ma-0 pa-0" ref="form" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Data Bet</p>
                    <v-text-field
                      v-for="(item, index) in formData.data_bet"
                      :key="index"
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="formData.data_bet[index]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Amount</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="formData.amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Betting result</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="formData.betting_result"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Result</p>
                    <v-select
                      label="Bet result"
                      :items="betResults"
                      v-model="formData.result"
                      v-bind="textFieldProp"
                      dense
                      dark
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-6">
                    <v-btn
                      block
                      large
                      outlined
                      dark
                      @click="validate"
                      depressed
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span>แก้ไขเบท</span>
          </h1>
          <p class="text-sm font-light">
            <span v-if="!editID"> คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span>
            <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn color="error" depressed text tile @click="confirmCreateDialog = false">
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmit"> ยืนยัน </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import DataTable from "@/components/tables/BetDataTable";
import moment from "moment";

export default {
  data() {
    return {
      drawer: false,
      confirmCreateDialog: false,
      nameRules: [(v) => !!v || "*กรุณากรอกข้อมูล"],
      weightRules: [
        (v) => !!v || "*กรุณากรอกข้อมูล",
        (v) => v > 0 || "น้ำหนักไม่ถูกต้อง",
      ],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF",
      },
      editID: null,
      betResults: ["win", "lose"],
      matchStatusDialog: false,
      formData: {
        memberId: "",
        matchId: "",
        type_of_bet: "",
        data_bet: [],
        amount: 0,
        result: "",
        betting_result: 0,
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Username", value: "member.username" },
        { text: "ชื่อ", value: "member.name" },
        { text: "แมทช์", value: "match.name" },
        { text: "สถานะ", value: "match.status" },
        { text: "ประเภท", value: "type_of_bet" },
        { text: "เดิมพัน", value: "data_bet" },

        { text: "จำนวนเดิมพัน", value: "amount" },
        { text: "ราคา", value: "match" },
        { text: "ผลการแข่ง ", value: "result" },
        { text: "ผลการเดิมพัน", value: "betting_result" },
        { text: "เวลาเดิมพัน", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      meta: {},
      desserts: [],
      params: {
        limit: 10,
        page: 1,
        search: null,

        amount: null,
        betting_result: null,
        result: null,
        member_id: null,
        member_username: null,
        member_name: null,
        match_name: null,
        match_status: null,
        match_date: null,
      },
      filterTypeOfCreate: ["user", "member", "system"],
      filterStatus: ["pending", "open", "close", "cancel", "done"],
      isActive: [true, false],
    };
  },
  watch: {
    drawer(value) {
      !value && this.resetForm();
    },
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    },
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },
    resetForm() {
      this.formData = this.$options.data.call(this).formData;
      this.$refs.form.resetValidation();
      this.editID = null;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.confirmCreateDialog = true;
      }
    },
    async onSubmit() {
      try {
        const { status } = await HorseService.updateBet(this.editID, {
          data_bet: this.formData.data_bet,
          amount: Number(this.formData.amount),
          result: this.formData.result,
          betting_result: this.formData.betting_result,
        });

        if (status === 201 || status === 200) {
          this.drawer = false;
          this.confirmCreateDialog = false;
          this.resetForm();

          this.findAll(this.params);
        }
      } catch (error) {
        console.log(error);
      }
    },
    editItem(value) {
      if (!value) return false;

      this.editID = value.id;
      this.formData = this.lodash.cloneDeep(value);
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const deleted = await HorseService.deleteBet(value.id);

      if (deleted.status === 200) {
        this.findAll(this.params);
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findAll(options) {
      try {
        const { data, status } = await HorseService.findAllBet(options);
        this.params.match_date = options.match_date
        this.params.match_name = options.match_name

        if (status === 200) {
          this.desserts = data.items;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$nextTick(() =>
      this.findAll({
        ...this.params,
        match_date: moment(this.$route.query.date).format("YYYY-MM-D"),
        match_name: this.$route.query.name,
      })
    );

    this.sockets.subscribe("getHorseBet", (response) => {
      if (response === "fetchBetAdmin") {
        this.findAll(this.params);
      }
    });
  },
  components: {
    DataTable,
  },
};
</script>
