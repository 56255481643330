import { backendAPI } from "./../utils/axios";

const prefix = "/horse/";
class HorseService {
  // Horse
  async createHorse(data) {
    return await backendAPI.post(`${prefix}horse/create`, data);
  }
  async findAllHorse(params) {
    return await backendAPI.get(`${prefix}horse`, { params });
  }
  async updateHorse(id, data) {
    const response = await backendAPI.put(`${prefix}horse/${id}`, data);

    return response;
  }
  async deleteHorse(id) {
    const response = await backendAPI.delete(`${prefix}horse/${id}`);

    return response;
  }
  async listHorse(search) {
    const params = { search };
    return await backendAPI.get(`${prefix}horse-list`, { params });
  }
  async findOneHorse(id) {
    return await backendAPI.get(`${prefix}horse/${id}`);
  }
  async updateMatchStatusDone(id, status) {
    const response = await backendAPI.put(
      `${prefix}match/updateStatusMatch/${id}`,
      { status }
    );

    return response;
  }
  // Jockey
  async createJockey(data) {
    return await backendAPI.post(`${prefix}jokey/create`, data);
  }
  async updateJockey(id, data) {
    const response = await backendAPI.put(`${prefix}jokey/${id}`, data);

    return response;
  }
  async findAllJockey(params) {
    return await backendAPI.get(`${prefix}jokey`, { params });
  }
  async listJockey(search) {
    const params = { search };
    return await backendAPI.get(`${prefix}jokey-list`, { params });
  }
  async deleteJockey(id) {
    const response = await backendAPI.delete(`${prefix}jokey/${id}`);

    return response;
  }
  // Bill
  async createBill(data) {
    return await backendAPI.post(`${prefix}bill/create`, data);
  }
  async updateBill(id, data) {
    const response = await backendAPI.put(`${prefix}bill/${id}`, data);

    return response;
  }
  async findAllBill(params) {
    return await backendAPI.get(`${prefix}bill`, { params });
  }
  async deleteBill(id) {
    const response = await backendAPI.delete(`${prefix}bill/${id}`);

    return response;
  }
  // Bet
  async createBet(data) {
    return await backendAPI.post(`${prefix}bet/create`, data);
  }
  async findAllBet(params) {
    return await backendAPI.get(`${prefix}bet-paginate`, {
      params,
      showLoader: false
    });
  }
  async deleteBet(id) {
    const response = await backendAPI.delete(`${prefix}bet/${id}`);

    return response;
  }
  async updateBet(id, data) {
    const response = await backendAPI.put(`${prefix}bet/${id}`, data);

    return response;
  }
  // Match
  async createMatch(data) {
    return await backendAPI.post(`${prefix}match/create`, data);
  }
  async updateMatch(id, data) {
    const response = await backendAPI.put(`${prefix}match/${id}`, data);

    return response;
  }
  async findAllMatch(params, showLoader = true) {
    return await backendAPI.get(`${prefix}match`, {
      params,
      showLoader
    });
  }
  async deleteMatch(id) {
    const response = await backendAPI.delete(`${prefix}match/${id}`);

    return response;
  }
  async findAllPartner(params) {
    return await backendAPI.get(`${prefix}bet-partner`, { params });
  }
  async findAllPartnerInvite(params) {
    return await backendAPI.get(`${prefix}bet-member-invite`, { params });
  }
  async findAllMatchDate(params) {
    return await backendAPI.get(`${prefix}matchByDate`, {
      params,
      showLoader: false
    });
  }
  async createIbc(data) {
    return await backendAPI.post(`${prefix}ibc/create`, data);
  }
  async findAllCommission(params) {
    return await backendAPI.get(
      `${prefix}ibc/findByMember/${params.member_invite_by_id}`,
      { params }
    );
  }
  async updateIbc(params, id) {
    return await backendAPI.put(`${prefix}ibc/${id}`, params);
  }
  async matchResult(id) {
    return await backendAPI.get(`${prefix}match/MatchResult/${id}`);
  }
  async autoUpdatePriceMatchQuinTri(id) {
    return await backendAPI.get(
      `${prefix}bet/autoUpdatePriceMatchQuinTri/${id}`
    );
  }
}

export default new HorseService();
