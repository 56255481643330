<template>
  <div>
    <v-card class="my-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="9" sm="9" md="9">
            <v-form>
              <v-row align="center">
                <v-col cols="12" lg="8" sm="8" md="8"
                  ><v-text-field
                    outlined
                    v-model="params.search"
                    prepend-inner-icon="mdi mdi-magnify"
                    label="ค้นหา"
                    dense
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="6" lg="auto" md="auto" sm="auto">
                  <v-btn color="purple" block dark @click="search">
                    <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                  >
                </v-col>
                <v-col cols="6" lg="auto" md="auto" sm="auto">
                  <v-btn
                    elevation="2"
                    color="purple"
                    block
                    outlined
                    class="mx-1"
                    @click="clear"
                  >
                    <v-icon>mdi-sync</v-icon>
                    เคลียร์
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="auto" sm="auto" md="auto">
            <v-btn
              block
              color="primary"
              class="font-weight-bold"
              @click="createBonus"
            >
              <v-icon>mdi mdi-plus</v-icon>
              สร้างโบนัส</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <DataTable
          :headers="headers"
          :desserts="bonusItems"
          :meta="bonusMeta"
          @deleteItem="deleteItem"
          @editItem="editItem"
          @selectPage="selectPage"
          @setCurrentPage="setCurrentPage"
        />
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar
            flat
            dark
            dense
            color="transparent"
            width="100%"
            max-height="50px"
          >
            <v-btn
              icon
              dark
              @click="setCreateBonusDialog(false)"
              class="focus:outline-none"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span v-if="!editID">สร้างโบนัส</span>
            <span v-else>แก้ไขโบนัส</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form
                class="ma-0 pa-0"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row no-gutters>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      หัวข้อ
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formBonus.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      รายละเอียด
                    </p>
                    <v-textarea
                      rows="4"
                      dark
                      v-bind="textFieldProp"
                      v-model="formBonus.description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      ประเภท
                    </p>
                    <v-select
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      :items="typeOfBonus"
                      v-model="formBonus.type_of_bonus"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="6"
                    class="px-2 py-0"
                    v-if="formBonus.type_of_bonus === 'percent'"
                  >
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      สูงสุด
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      type="number"
                      v-bind="textFieldProp"
                      v-model="formBonus.max"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">โบนัส</p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      type="number"
                      v-bind="textFieldProp"
                      v-model="formBonus.bonus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      ประเภทโบนัส {{ formBonus.type_transaction_bonus }}
                    </p>
                    <v-select
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      :items="typeTransactionBonus"
                      v-model="formBonus.type_transaction_bonus"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      เริ่มใช้งาน
                    </p>

                    <v-dialog
                      ref="effectiveDateDialog"
                      v-model="effectiveDateDialog"
                      :return-value.sync="formBonus.effective_date"
                      persistent
                      max-width="350"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formBonus.effective_date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          :rules="nameRules"
                          required
                          dark
                          v-bind="{ ...textFieldProp, attrs }"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formBonus.effective_date"
                        scrollable
                        :rules="nameRules"
                        required
                        locale="th"
                        width="100%"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="effectiveDateDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.effectiveDateDialog.save(
                              formBonus.effective_date
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      หมดอายุ
                    </p>

                    <v-dialog
                      ref="expireDateDialog"
                      v-model="expireDateDialog"
                      :return-value.sync="formBonus.expire_date"
                      persistent
                      max-width="350"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formBonus.expire_date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dark
                          :rules="nameRules"
                          required
                          v-bind="{ ...textFieldProp, attrs }"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formBonus.expire_date"
                        scrollable
                        :rules="nameRules"
                        required
                        locale="th"
                        width="100%"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="expireDateDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.expireDateDialog.save(formBonus.expire_date)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6">
                    <v-btn
                      block
                      large
                      dark
                      outlined
                      depressed
                      @click="confirmCreateDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span v-if="!editID">สร้างโบนัส</span>
              <span v-else>แก้ไขโบนัส</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable";
import BonusService from "./../../services/bonus.service";
import { mapGetters, mapActions } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
export default {
  data() {
    return {
      valid: false,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      editID: null,
      typeOfBonus: ["percent", "fix"],
      typeTransactionBonus: ["deposit", "transferin"],
      confirmCreateDialog: false,
      effectiveDateDialog: false,
      expireDateDialog: false,
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      params: {
        limit: 10,
        page: 1,
        search: null
      },
      headers: [
        {
          text: "ชื่อ",
          align: "start",
          value: "title"
        },
        { text: "รายละเอียด", value: "description" },
        { text: "ประเภท", value: "type_of_bonus" },
        { text: "โบนัส", value: "bonus" },
        { text: "สูงสุด", value: "max" },
        { text: "เริ่ม", value: "effective_date" },
        { text: "สิ้นสุด", value: "expire_date" },
        { text: "Actions", value: "actions" }
      ]
    };
  },
  methods: {
    ...mapActions({
      findAll: "bonus/findAll",
      setBonusItems: "bonus/setBonusItem",
      setCreateBonusDialog: "bonus/setCreateBonusDialog",
      clearState: "bonus/clearState",
      setFormBonus: "bonus/setFormBonus"
    }),
    createBonus() {
      this.editID = null;
      this.setCreateBonusDialog(true);
    },
    clear() {
      this.params = {
        limit: 10,
        page: 1,
        search: null
      };

      this.findAll(this.params);
    },
    async deleteItem(value) {
      await BonusService.delete(value.id);
      this.findAll(this.params);
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    editItem(value) {
      this.editID = value.id;

      (value.effective_date = new Date(value.effective_date)
        .toISOString()
        .substr(0, 10)),
        (value.expire_date = new Date(value.expire_date)
          .toISOString()
          .substr(0, 10)),
        this.setCreateBonusDialog(true);
      this.setFormBonus(cloneDeep(value));
    },

    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.formBonus.bonus = parseInt(this.formBonus.bonus);
        this.formBonus.expire_date = moment(
          new Date(this.formBonus.expire_date)
        ).endOf("day");

        if (!this.editID) {
          //Create bonus
          const { data, status } = await BonusService.create(this.formBonus);
          if (status === 201) {
            // status 201 created
            if (this.bonusItems.length !== 10) {
              this.bonusItems.push(data);
            }
            this.bonusMeta.totalItems++;
            this.bonusMeta.totalPages = Math.ceil(
              this.bonusMeta.totalItems / this.bonusMeta.itemsPerPage
            );
          }
        } else {
          // Edit bonus
          const { data, status } = await BonusService.update(this.editID);
          if (status === 200) {
            const results = [];
            this.bonusItems.map((item, index) => {
              if (this.editID === item.id) {
                results[index] = data;
              } else {
                results[index] = item;
              }
            });
            this.setBonusItems(results);
            this.editID = null;
          }
        }
        this.setCreateBonusDialog(false);
        this.confirmCreateDialog = false;
        this.$refs.form.resetValidation();
        this.clearState();
      } else {
        this.confirmCreateDialog = false;
      }
    },
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  watch: {
    drawer(value) {
      if (!value) {
        this.$refs.form.resetValidation();
      }
    }
  },
  computed: {
    ...mapGetters({
      bonusItems: "bonus/bonusItems",
      dialog: "bonus/createBonusDialog",
      formBonus: "bonus/formBonus",
      pageSize: "bonus/pageSize",
      pageTotal: "bonus/pageTotal",
      bonusMeta: "bonus/bonusMeta"
    }),
    drawer: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.setCreateBonusDialog(value);
      }
    }
  },
  components: {
    DataTable
  }
};
</script>
