<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="ค้นหา"
                        v-model="params.search"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-expansion-panels class="my-4" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >ค้นหาเพิ่มเติม</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Invite by username"
                              v-model="params.member_invite_by_username"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Member invite by name"
                              v-model="params.member_invite_by_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-dialog
                              ref="matchStatusDialog"
                              v-model="matchStatusDialog"
                              :return-value.sync="params.match_date"
                              persistent
                              max-width="350"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="params.match_date"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  dense
                                  hide-details
                                  outlined
                                  v-bind="{ attrs }"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="params.match_date"
                                scrollable
                                locale="th"
                                width="100%"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="matchStatusDialog = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.matchStatusDialog.save(
                                      params.match_date
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn color="purple" block dark @click="search">
                              <v-icon> mdi mdi-magnify</v-icon> ค้นหา
                            </v-btn>
                          </v-col>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn
                              elevation="2"
                              color="purple"
                              block
                              outlined
                              class="mx-1"
                              @click="clear"
                            >
                              <v-icon>mdi-sync</v-icon>
                              เคลียร์
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <CommissionDataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
              @onEdit="onEdit"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-form class="ma-0 pa-0" ref="form" lazy-validation>
        <v-card>
          <v-card-text>
            <h1 class="text-center text-2xl font-bold text-black mb-0 pb-0">
              แก้ไข
            </h1>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" class="px-4">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    คอมมิชชั่น
                  </p>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="editItem.total_af"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="px-4">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    สถานะเคลม
                  </p>
                  <v-select
                    label="สถานะเคลม"
                    v-model="editItem.claim_status"
                    outlined
                    dense
                    item-value="value"
                    item-text="label"
                    hide-details="auto"
                    :items="statusItems"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" class="px-4">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    ล็อค
                  </p>
                  <v-select
                    label="ล็อค"
                    v-model="editItem.is_active"
                    outlined
                    dense
                    item-value="value"
                    item-text="label"
                    hide-details="auto"
                    :items="activeItems"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="dialog = false">
              ยกเลิก
            </v-btn>
            <v-btn color="success" text @click="onCommissionEdit">
              บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import CommissionDataTable from "@/components/tables/CommissionDataTable";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      editItem: {
        total_af: 0,
        is_active: false,
        claim_status: false
      },
      statusItems: [
        { value: true, label: "เคลมแล้ว" },
        { value: false, label: "ยังไม่เคลม" }
      ],
      activeItems: [
        { value: true, label: "ไม่" },
        { value: false, label: "ล็อค" }
      ],
      headers: [
        { text: "ID", value: "id" },
        { text: "ชื่อสมาชิก", value: "member.name" },
        { text: "Username", value: "member.username" },
        { text: "คอมมิชชั่น", value: "total_af" },
        { text: "สถานะเคลม", value: "claim_status" },
        { text: "ล็อค", value: "is_active" },
        { text: "วันที่", value: "match_date" },
        { text: "Actions", value: "actions" }
      ],
      meta: {},
      desserts: [],
      filterStatus: ["pending", "open", "close", "cancel", "done"],
      matchStatusDialog: false,
      params: {
        limit: 10,
        page: 1,
        search: null,
        match_date: "",
        member_invite_by_username: "",
        member_invite_by_name: ""
      }
    };
  },

  watch: {
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },

    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.dialog = true;
      }
    },
    onEdit(value) {
      this.dialog = true;
      this.editItem = this.lodash.cloneDeep(value);
    },
    async onCommissionEdit() {
      try {
        if (!this.$refs.form.validate()) return;
        const { status, data } = await HorseService.updateIbc(
          this.editItem,
          this.$route.query.member
        );

        if (status !== 200) {
          this.alert({
            type: "error",
            text: data?.message || "ไม่สามารถแก้ไขได้กรุณาลองอีกครั้ง"
          });
          return;
        }
        this.dialog = false;
        await this.findAll(this.params);
        this.alert({
          type: "success",
          text: "แก้ไขข้อมูลเสร็จสิ้น"
        });
      } catch (error) {
        this.alert({
          type: "error",
          text: error.message
        });
      }
    },
    ...mapActions({
      alert: "notification/show"
    }),
    async findAll(query) {
      try {
        const { data, status } = await HorseService.findAllCommission({
          ...query,
          member_invite_by_id: this.$route.query.member,
          match_date: this.$route.query.date
        });
        this.params.match_date = this.$route.query.date;

        if (status === 200) {
          this.desserts = data.items;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.findAll(this.params);
    });
  },
  components: {
    CommissionDataTable
  }
};
</script>
