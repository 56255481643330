<template>
  <div>
    <v-navigation-drawer
      v-model="drawerStatus"
      fixed
      right
      temporary
      class="h-fll bg-black bg-gradient-purple"
      :width="$vuetify.breakpoint.mobile ? '100%' : '80%'"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar flat dark dense color="transparent" max-height="50px">
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            สร้างคนขี่
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form class="ma-0 pa-0" ref="form" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">ชื่อ</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="formData.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      น้ำหนัก
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="weightRules"
                      v-model="formData.weight"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      น้ำหนักแปรผัน
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="weightRules"
                      v-model="formData.weight_variable"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-6">
                    <v-btn
                      block
                      large
                      outlined
                      dark
                      @click="validate"
                      depressed
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span>สร้างคนขี่</span>
          </h1>
          <p class="text-sm font-light">
            <span> คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmCreateDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmit">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HorseService from "./../services/horse.service";
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmCreateDialog: false,
      nameRules: [(v) => !!v || "*กรุณากรอกข้อมูล"],
      weightRules: [
        (v) => !!v || "*กรุณากรอกข้อมูล",
        (v) => v > 0 || "น้ำหนักไม่ถูกต้อง"
      ],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      formData: {
        name: "",
        weight: 1,
        weight_variable: 1
      }
    };
  },
  computed: {
    drawerStatus: {
      get() {
        return this.drawer;
      },
      set(v) {
        this.$emit("drawer", v);
      }
    }
  },
  methods: {
    resetForm() {
      this.formData = this.$options.data.call(this).formData;
      this.$refs.form.resetValidation();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.confirmCreateDialog = true;
      }
    },
    async onSubmit() {
      try {
        this.formData.weight = parseFloat(this.formData.weight);
        this.formData.weight_variable = parseFloat(
          this.formData.weight_variable
        );

        const { status } = await HorseService.createJockey(this.formData);

        if (status === 201 || status === 200) {
          this.confirmCreateDialog = false
          this.$emit("drawer", false);
          this.$emit("callback", "success");

          this.resetForm();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>