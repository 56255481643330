<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="onEdit(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.match_date`]="{ item }">
        {{ item.match_date | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.claim_status`]="{ item }">
        {{ item.claim_status | claimStatus }}
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        {{ item.is_active | activeStatus }}
      </template>
      <template v-slot:[`item.invite_by_name`]="{ item }">
        {{ item.invite_by_username }} : {{ item.invite_by_name }}
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "./../../utils/role";

export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    },
    scope: {
      type: Array,
      default: () => ["edit", "delete"]
    }
  },
  data() {
    return {
      deleteValue: [],
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions
    };
  },
  filters: {
    claimStatus(value) {
      switch (value) {
        case false:
          return "ยังไม่เคลม";
        case true:
          return "เคลมแล้ว";
        default:
          return "";
      }
    },
    activeStatus(value) {
      switch (value) {
        case false:
          return "ล็อค";
        case true:
          return "ไม่";
        default:
          return "";
      }
    }
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  methods: {
    onEdit(values) {
      this.$emit("onEdit", values);
    },
    checkScope(value) {
      return !!this.scope.find(item => item === value);
    },
    //  editItem(value) {
    //    this.$emit("editItem", value);
    //  },
    //  deleteItem(value) {
    //    this.confirmDeleteDialog = true;
    //    this.deleteValue = value;
    //  },
    //  comfirmDelete() {
    //    this.$emit("deleteItem", this.deleteValue);
    //    this.confirmDeleteDialog = false;
    //  },
    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
