<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          size="20"
          color="success"
          @click="onCreateDeposit(item)"
          v-if="permission(roleOptions.deposit)"
        >
          mdi-plus </v-icon
        ><v-icon
          class="mr-2"
          size="20"
          color="error"
          v-if="permission(roleOptions.withdraw)"
          @click="onCreateWithDraw(item)"
        >
          mdi-minus
        </v-icon>

        <v-icon
          class="mr-2"
          color="#6101CF"
          size="20"
          @click="transfer(item)"
          v-if="permission(roleOptions.transaction)"
        >
          mdi-bank-transfer
        </v-icon>
        <v-icon
          small
          class="mr-2" 
          @click="editItem(item)"
          v-if="permission(roleOptions.update)"
        >
          mdi-pencil
        </v-icon>
         <v-icon
          small
          color="#0F2C67"
          class="mr-2"
          @click="updateCredit(item)"
          v-if="rolePage.member.credit"
        >
          mdi-cash-multiple
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="permission(roleOptions.delete)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span v-if="role !== 'member'">
          {{ item.status }}
        </span>
        <span v-else-if="role === 'member'">
          {{ item.status | statusMember }}
        </span>
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">ลบ</h1>
          <p class="text-sm font-light">
            คุณต้องการที่จะลบรายการนี้ หรือไม่?
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmDeleteDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="comfirmDelete">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "./../../utils/role";
export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: Object,
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      deleteValue: [],
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    },
    rolePage(){
      const user = JSON.parse(localStorage.getItem('user'))
      return JSON.parse(user.role)
    }
  },
  methods: {
    editItem(value) {
      this.$emit("editItem", value);
    },
    deleteItem(value) {
      this.confirmDeleteDialog = true;
      this.deleteValue = value;
    },
    comfirmDelete() {
      this.$emit("deleteItem", this.deleteValue);
      this.confirmDeleteDialog = false;
    },
    transfer(value) {
      this.$emit("transfer", value);
    },
    onCreateDeposit(item) {
      this.$emit("onCreateDeposit", item);
    },
    onCreateWithDraw(item) {
      this.$emit("onCreateWithDraw", item);
    },
    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    },
    updateCredit(item){
      this.$emit('updateCredit' , item)
    }
  }
};
</script>
