<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat color="transparent">
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="9" sm="9" md="9"> </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" lg="auto" sm="auto" md="auto">
                <v-btn
                  block
                  dark
                  color="#ce1212"
                  class="font-weight-bold"
                  @click="drawer = true"
                  v-if="
                    $actionPermisstion($roleMenu.withdraw, $roleOptions.create)
                  "
                >
                  <v-icon>mdi mdi-minus</v-icon>
                  ถอนเครดิต</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ApproveWithdraw
          :fetchData="fetchData"
          @approveStatus="approveStatus"
        />
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12"
                      ><v-text-field
                        outlined
                        v-model="params.search"
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <v-expansion-panels class="my-4" flat>
              <v-expansion-panel>
                <v-expansion-panel-header @click="clearSearch"
                  >ค้นหาเพิ่มเติม</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row align="center">
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="จำนวน"
                        v-model="params.amount"
                        outlined
                        type="number"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="เลขธุรกรรมสมาชิก"
                        v-model="params.member_id"
                        outlined
                        type="number"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="Username สมาชิก"
                        v-model="params.member_username"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="ชื่อสมาชิก"
                        v-model="params.member_name"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="ประเภท transaction"
                        v-model="params.transaction_type"
                        outlined
                        dense
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="selectOptions.transaction_type"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="สถานะ"
                        v-model="params.status"
                        outlined
                        dense
                        hide-details
                        :items="selectOptions.status"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="Active"
                        v-model="params.is_active"
                        outlined
                        dense
                        hide-details
                        :items="selectOptions.isActive"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" lg="3" md="3" sm="3">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="3" md="3" sm="3">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-text>
            <TransactionDatatable
              :headers="headers"
              :desserts="desserts"
              :meta="meta"
              @copyItem="copyItem"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
              role="withdraw"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create -->

    <CreateDrawer
      :drawer="drawer"
      :createForm="createForm"
      :memberItems="memberItems"
      :editID="editID"
      @onClose="value => (drawer = value)"
      @memberSearch="memberSearch"
      @onSubmitWithdraw="onSubmitWithdraw"
      @onUpdateWithdraw="onUpdateWithdraw"
    />

    <MemberDetailDialog
      :dialog="memberDetailDialog"
      :memberData="memberDetailData"
      @onClose="value => (memberDetailDialog = value)"
    />
  </div>
</template>

<script>
import TransactionDatatable from "@/components/tables/TransactionDatatable";
import TransactionService from "../../services/transaction.service";
import MemberService from "../../services/member.service";

import CreateDrawer from "./components/drawer/CreateDrawer";
import ApproveWithdraw from "./components/layouts/ApproveWithdraw";
import MemberDetailDialog from "./components/dialogs/MemberDetailDialog";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      fetchData: false,
      headers: [
        { text: "ธุรกรรม", value: "id" },
        { text: "Username", value: "member.username" },
        { text: "Name", value: "member.name" },
        { text: "Bank", value: "member.bank" },
        { text: "Account number", value: "member.account_number" },
        { text: "Amount", value: "amount" },
        { text: "สร้างโดย", value: "create_by.name" },
        { text: "เวลาทำรายการ", value: "created_at" },
        { text: "ก่อนถอน", value: "credit_before" },
        { text: "หลังถอน", value: "credit_after" },
        // { text: "สถานะ", value: "is_active" },
        { text: "API", value: "is_auto" },
        { text: "สถานะ(API)", value: "status_api_auto" },
        // { text: "หมายเหตุ", value: "description" },
        { text: "สถานะ(ลบ)", value: "is_active" },
        { text: "แก้ไข", value: "update_by.name" },
        { text: "เวลาแก้ไขล่าสุด", value: "updated_at" },
        { text: "Actions", value: "actions" }
      ],
      desserts: [],
      meta: {},
      editID: null,
      drawer: false,
      memberItems: [],
      createForm: {
        memberId: null,
        amount: 0,
        description: null,
        status: "pending",
        transaction_type: "withdraw",
        type_of_created: "user"
      },
      memberDetailDialog: false,
      memberDetailData: null,
      params: {
        page: 1,
        limit: 10,
        search: null,
        amount: null,
        is_active: null,
        status: "done",
        type_of_created: null,
        member_id: null,
        member_username: null,
        member_name: null,
        transaction_type: "withdraw"
      },
      selectOptions: {
        transaction_type: [
          {
            label: "ถอนเครดิต",
            value: "withdraw"
          }
        ],
        isActive: [true, false],
        typeOfCreated: ["user", "member", "system"],
        status: ["pending", "done", "failure"]
      },
      panel: false
    };
  },
  methods: {
    ...mapActions({
      alert: "notification/show"
    }),
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },
    clearSearch() {
      this.params.search = null;
    },
    approveStatus(value) {
      value === "success" && this.findAll(this.params);
    },
    async findAll(options) {
      const response = await TransactionService.findAll(options);
      if (response.status === 200) {
        this.desserts = response.data.items;
        this.meta = response.data.meta;
      }
    },

    async memberSearch(username) {
      const memberData = await MemberService.list(username);
      if (memberData.status === 200) {
        this.memberItems = memberData.data;
      }
    },
    async onSubmitWithdraw(values) {
      const withdraw = await TransactionService.withdraw(values);
      // Socket
      const room = withdraw.data.member.id.toString();

      this.$socket.emit("setSocketWithdraw", room);
      this.$socket.emit("setSocketNotificationWithdraw", room);
      this.$socket.emit("setSocketNotificationAdminApproveWithdraw");
      //End Socket

      this.alert({ type: "success", text: "ถอนเครดิตเรียบร้อย" });
      if (withdraw.status === 201) {
        this.findAll(this.params);
        this.fetchData = true;
      } else {
        this.alert({ type: "error", text: "ไม่สามารถแก้ไขถอนดิตได้" });
      }
    },
    async onUpdateWithdraw(values) {
      const withdraw = await TransactionService.update(this.editID, values);

      if (withdraw.status === 200) {
        this.editID = null;
        this.alert({ type: "success", text: "แก้ไขรายการถอนเครดิตเรียบร้อย" });
        this.findAll(this.params);
      }else {
        this.alert({ type: "error", text: "ไม่สามารถแก้ไขรายการถอนเครดิตได้" });
      }
    },
    editItem(value) {
      if (!value) return false;

      this.editID = value.id;
      this.createForm = this.lodash.cloneDeep(value);
      this.createForm.memberId = value.member;
      this.memberSearch(value.member.username);
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const { id } = value;
      const deleted = await TransactionService.delete(id);

      if (deleted.status === 200) {
        this.findAll(this.params);
      }
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    clearForm() {
      this.createForm = {
        memberId: null,
        amount: 0,
        description: null,
        status: "pending",
        transaction_type: "withdraw",
        type_of_created: "user"
      };
    },
    copyItem(value) {
      this.memberDetailData = value;
      this.memberDetailDialog = true;
    }
  },
  watch: {
    async drawer(value) {
      this.memberSearch();
      if (!value) {
        this.clearForm();
        this.editID = null;
      }
      this.fetchData = false;
    },
    "params.search"(value) {
      if (value) this.panel = undefined;
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    },
    panel(status) {
      if (status !== undefined) {
        this.params = this.$options.data.call(this).params;
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  components: {
    TransactionDatatable,
    CreateDrawer,
    ApproveWithdraw,
    MemberDetailDialog
  }
};
</script>
