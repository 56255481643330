<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat color="transparent">
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" lg="auto" sm="auto" md="auto">
                <v-btn
                  block
                  dark
                  color="#289672"
                  class="font-weight-bold"
                  @click="drawer = true"
                  v-if="
                    $actionPermisstion($roleMenu.deposit, $roleOptions.create)
                  "
                >
                  <v-icon>mdi mdi-plus</v-icon>
                  เติมเครดิต</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ApproveDeposit :fetchData="fetchData" @approveStatus="approveStatus" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12"
                      ><v-text-field
                        outlined
                        v-model="params.search"
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <v-expansion-panels class="my-4" flat>
              <v-expansion-panel>
                <v-expansion-panel-header @click="clearSearch"
                  >ค้นหาเพิ่มเติม</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row align="center">
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="จำนวน"
                        v-model="params.amount"
                        outlined
                        type="number"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="เลขธุรกรรมสมาชิก"
                        v-model="params.member_id"
                        outlined
                        type="number"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="Username สมาชิก"
                        v-model="params.member_username"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="ชื่อสมาชิก"
                        v-model="params.member_name"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="ประเภท transaction"
                        v-model="params.transaction_type"
                        outlined
                        dense
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="selectOptions.transaction_type"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="สถานะ"
                        v-model="params.status"
                        outlined
                        dense
                        hide-details
                        :items="selectOptions.status"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="Active"
                        v-model="params.is_active"
                        outlined
                        dense
                        hide-details
                        :items="selectOptions.isActive"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" lg="3" md="3" sm="3">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="3" md="3" sm="3">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <TransactionDatatable
              :headers="headers"
              :desserts="desserts"
              :meta="meta"
              @copyItem="copyItem"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
              role="deposit"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create -->

    <FormDrawer
      :drawer="drawer"
      :createForm="createForm"
      :bonusItems="bonusItems"
      :memberItems="memberItems"
      :editID="editID"
      :isLoading="isLoading"
      @onClose="value => (drawer = value)"
      @memberSearch="memberSearch"
      @onSubmitDeposit="onSubmitDeposit"
      @onUpdateDeposit="onUpdateDeposit"
    />

    <MemberDetailDialog
      :dialog="memberDetailDialog"
      :memberData="memberDetailData"
      @onClose="value => (memberDetailDialog = value)"
    />
  </div>
</template>

<script>
import TransactionDatatable from "@/components/tables/TransactionDatatable";
import TransactionService from "../../services/transaction.service";
import BonusService from "../../services/bonus.service";
import MemberService from "../../services/member.service";

import FormDrawer from "./components/drawer/FormDrawer";
import ApproveDeposit from "./components/layouts/ApproveDeposit";
import MemberDetailDialog from "./components/dialogs/MemberDetailDialog";
import { mapActions } from "vuex";
import moment from "moment-timezone";
export default {
  data() {
    return {
      headers: [
        { text: "ธุรกรรม", align: "center", value: "id" },
        { text: "Username", value: "member.username" },
        { text: "ชื่อ", value: "member.name" },
        { text: "ธนาคาร", value: "member.bank" },
        { text: "เลขบัญชี", value: "member.account_number" },
        { text: "จำนวน", value: "amount" },
        { text: "จำนวน Bonus ", value: "bonus_amount" },
        { text: "โปรโมชั่น", value: "bonus.title" },
        { text: "เวลา SMS", value: "sms_time" },
        // { text: "ก่อนเติม", value: "credit_before" },
        // { text: "หลังเติม", value: "credit_after" },
        // { text: "สถานะ", value: "is_active" },
        // { text: "หมายเหตุ", value: "description" },
        { text: "API", value: "is_auto" },
        { text: "Status", value: "is_active" },
        { text: "สร้างโดย", value: "create_by.name" },
        { text: "เวลาทำรายการ", value: "created_at" },
        { text: "ยกเลิกโดย", value: "update_by.name" },
        { text: "เวลายกเลิก", value: "updated_at" },
        // { text: "เวลาแก้ไขล่าสุด", value: "updated_at" },
        { text: "Actions", value: "actions", width: "100px" }
      ],
      desserts: [],
      meta: {},
      editID: null,
      drawer: false,
      bonusItems: [],
      memberItems: [],
      createForm: {
        memberId: "",
        amount: 0,
        bonusId: null,
        description: null,
        sms_time: new Date(),
        status: "pending",
        transaction_type: "deposit",
        type_of_created: "user",
        is_register: false,
        inviteId: null,
        use_api: false,
        update_credit: true
      },
      depositAuto: [],
      memberDetailData: null,
      memberDetailDialog: false,
      fetchData: false,
      params: {
        page: 1,
        limit: 10,
        search: null,
        amount: null,
        is_active: null,
        status: "done",
        type_of_created: null,
        member_id: null,
        member_username: null,
        member_name: null,
        transaction_type: "deposit"
      },
      selectOptions: {
        transaction_type: [
          {
            label: "เติมเครดิต",
            value: "deposit"
          }
        ],
        isActive: [true, false],
        typeOfCreated: ["user", "member", "system"],
        status: ["pending", "done", "failure"]
      },
      panel: false,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      alert: "notification/show"
    }),
    approveStatus(value) {
      value === "success" && this.findAll(this.params);
    },
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },
    clearSearch() {
      this.params.search = null;
    },
    async findAll(options) {
      const response = await TransactionService.findAll(options);
      if (response.status === 200) {
        this.desserts = response.data.items;
        this.meta = response.data.meta;
      }
    },

    async memberSearch(username) {
      this.isLoading = true;
      const memberData = await MemberService.list(username);
      if (memberData.status === 200) {
        this.memberItems = memberData.data;
        this.isLoading = false;
      }
    },
    async memberList() {
      const memberData = await MemberService.list();
      if (memberData.status === 200) {
        this.memberItems = memberData.data;
      }
    },
    async onSubmitDeposit(values) {
      const deposit = await TransactionService.deposit(values);

      if (deposit.status === 201) {
        // Socket
        const room = deposit.data.member.id.toString();

        this.$socket.emit("setSocketDeposit", room);
        this.$socket.emit("setSocketNotificationDeposit", room);
        this.$socket.emit("setSocketNotificationAdminApproveDeposit");
        //End Socket

        this.alert({ type: "success", text: "เติมเครดิตเรียบร้อย" });
        this.findAll(this.params);
      } else {
        this.alert({ type: "error", text: "ไม่สามารถแก้ไขเครดิตได้" });
      }
    },
    async onUpdateDeposit(values) {
      const deposit = await TransactionService.update(this.editID, values);

      if (deposit.status === 200) {
        this.editID = null;
        this.alert({ type: "success", text: "แก้ไขรายการเติมเครดิตเรียบร้อย" });
        this.findAll(this.params);
      } else {
        this.alert({ type: "error", text: "ไม่สามารถแก้ไขรายการเติมเครดิตได้" });
      }
    },
    editItem(value) {
      if (!value) return false;
      this.editID = value.id;
      this.createForm = this.lodash.cloneDeep(value);
      this.createForm.memberId = value.member;
      this.createForm.bonusId =  value.bonus;
      this.createForm.inviteId = value.invite
      this.createForm.sms_time = moment(value.sms_time).add(-7, "hours").format("YYYY-MM-D H:mm")

      this.memberSearch(value.member.username);
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const { id } = value;
      const deleted = await TransactionService.delete(id);

      if (deleted.status === 200) {
        this.alert({ type: "success", text: "ลบรายการเสร็จสิ้น" });
        this.findAll(this.params);
      } else {
        this.alert({ type: "error", text: "ไม่สามารถลบรายการนี้ได้" });
      }
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    clearForm() {
      this.createForm = this.$options.data.call(this).createForm;
    },
    copyItem(value) {
      this.memberDetailData = value;
      this.memberDetailDialog = true;
    },
    smsDate(value) {
      const date = moment(value).format("YYYY-MM-D H:mm");
      console.log(value)
      return date;
    }
  },
  watch: {
    async drawer(value) {
      if (!value) {
        this.clearForm();
        this.editID = null;
      } else {
        this.memberList();
        const bonusData = await BonusService.list();
        if (bonusData.status === 200) {
          this.bonusItems = bonusData.data.filter(
            ({ type_transaction_bonus }) => type_transaction_bonus === "deposit"
          );
        }
      }
      this.fetchData = false;
      if (!this.editID)
        this.createForm.sms_time = this.smsDate(this.createForm.sms_time);
    },
    "params.search"(value) {
      if (value) this.panel = undefined;
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    },
    panel(status) {
      if (status !== undefined) {
        this.params = this.$options.data.call(this).params;
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));
    this.memberList();
    this.createForm.sms_time = this.smsDate(this.createForm.sms_time);
  },
  components: {
    TransactionDatatable,
    FormDrawer,
    ApproveDeposit,
    MemberDetailDialog
  }
};
</script>
