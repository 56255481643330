<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="ค้นหา"
                        v-model="params.search"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-expansion-panels class="my-4" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >ค้นหาเพิ่มเติม</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Invite by id"
                              v-model="params.member_invite_by_id"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Invite by username"
                              v-model="params.member_invite_by_username"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="Member invite by name"
                              v-model="params.member_invite_by_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn color="purple" block dark @click="search">
                              <v-icon> mdi mdi-magnify</v-icon> ค้นหา
                            </v-btn>
                          </v-col>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn
                              elevation="2"
                              color="purple"
                              block
                              outlined
                              class="mx-1"
                              @click="clear"
                            >
                              <v-icon>mdi-sync</v-icon>
                              เคลียร์
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <PartnerDataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
              @onCreateIbc="onCreateIbc"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import PartnerDataTable from "@/components/tables/PartnerDatatable";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      statusItems: ["pending", "open", "close", "cancel", "done"],
      headers: [
        { text: "ID", value: "id" },
        { text: "ชื่อ", value: "name" },
        { text: "Username", value: "username" },
        { text: "ยอดรวมของสมาชิกที่แนะนำ", value: "total_betting_result" },
        { text: "Commission", value: "commission" },
        { text: "วันที่", value: "date" },
        { text: "Actions", value: "actions" }
      ],
      meta: {},
      desserts: [],
      filterStatus: ["pending", "open", "close", "cancel", "done"],
      params: {
        limit: 10,
        page: 1,
        search: null,
        member_invite_by_id: "",
        member_invite_by_username: "",
        member_invite_by_name: ""
      }
    };
  },

  watch: {
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },

    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findAll(query) {
      try {
        const date = this.$route.query.date;
        if (!date) this.$router.back();

        const { data, status } = await HorseService.findAllPartnerInvite({
          ...query,
          match_date: moment(this.$route.query.date).format("YYYY-MM-D")
        });
        let results = [];
        if (status === 200) {
          data.items.map(item => {
            results = [
              ...results,
              {
                ...item,
                date: moment(this.$route.query.date).format("YYYY-MM-D")
              }
            ];
          });
          this.desserts = results;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onCreateIbc(values) {
      if (!values) return;

      const formData = {
        memberId: Number(values.id),
        match_date: values.date,
        total_af: Number(values.commission)
      };

      const { status } = await HorseService.createIbc(formData);
      if (status === 201) {
        this.alert({ type: "success", text: "สรุปค่าคอมมิชชั่นสำเร็จ" });
      } else {
        this.alert({
          type: "error",
          text: "สรุปค่าคอมมิชชั่นผิดพลาดหรุณาลองอีกครั้ง"
        });
      }
    },
    ...mapActions({
      alert: "notification/show"
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.findAll(this.params);
    });
  },
  components: {
    PartnerDataTable
  }
};
</script>
