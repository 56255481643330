<template>
  <v-navigation-drawer
    v-model="createDrawer"
    fixed
    temporary
    right
    top
    :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
    color="#ce1212"
  >
    <v-row no-gutters justify="end">
      <v-col cols="12">
        <v-toolbar
          flat
          dark
          dense
          color="transparent"
          width="100%"
          max-height="50px"
        >
          <v-btn icon dark @click="onClose" class="focus:outline-none">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
          <span v-if="!editID">ถอนเครดิต</span>
          <span v-else>แก้ไขถอนเครดิต</span>
        </h1>

        <v-row no-gutters justify="center" class="pa-4">
          <v-col cols="12" lg="10">
            <v-form class="ma-0 pa-0" ref="form" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">สมาชิก</p>

                  <v-combobox
                    :rules="nameRules"
                    required
                    v-model="createForm.memberId"
                    :items="members"
                    :search-input.sync="memberSearch"
                    item-value="id"
                    item-text="username"
                    color="#fff"
                    dark
                    :clearable="!editID ? true : false"
                    :readonly="editID ? true : false"
                    hide-selected
                    return-object
                    :disabled="editID ? true : false"
                    v-bind="textFieldProp"
                  >
                    <template v-slot:item="{ item }">
                      <!-- <v-avatar size="35">
                        <v-img :src="item.image" width="20" height="20" contain>
                        </v-img>
                      </v-avatar> -->
                      <span class="px-2"
                        >{{ item.username }} : {{ item.name }}</span
                      >
                    </template>

                    <template v-slot:selection="{ item }">
                      <!-- <v-avatar :color="item.color" size="30">
                        <v-img :src="item.image" width="20" height="20" contain>
                        </v-img>
                      </v-avatar> -->
                      <span class="px-2"
                        >{{ item.username }} : {{ item.name }}</span
                      >
                    </template>
                  </v-combobox>
                  <div v-if="createForm.memberId" class="mb-4">
                    <p class="ma-0 pa-0 font-bold text-white text-lg">
                      เครดิตปัจจุบัน : {{ createForm.memberId.credit }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">จำนวน</p>
                  <v-text-field
                    dark
                    :rules="creditRules"
                    required
                    :disabled="editID ? true : false"
                    type="number"
                    v-model="createForm.amount"
                    v-bind="textFieldProp"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    รายละเอียด
                  </p>
                  <v-textarea
                    dark
                    rows="3"
                    v-model="createForm.description"
                    v-bind="textFieldProp"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row justify="start" no-gutters>
                <v-col cols="12" lg="6" sm="6" md="6" class="px-4">
                  <v-btn
                    block
                    large
                    dark
                    outlined
                    depressed
                    @click="confirmCreateDialog = true"
                    class="focus:outline-none border-2 rounded-lg"
                  >
                    <template v-slot:default>
                      <span class="text-bold text-lg">บันทึก</span>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span v-if="!editID">ถอนเครดิต</span>
            <span v-else>แก้ไขถอนเครดิต</span>
          </h1>
          <p class="text-sm font-light">
            <span v-if="!editID"> คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span>
            <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmCreateDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmit">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    editID: {
      type: [String, Number],
      default: null
    },

    memberItems: {
      type: [Array, Object],
      default: () => []
    },
    createForm: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#810000"
      },
      confirmCreateDialog: false,
      memberSearch: null
    };
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.createForm.amount = parseFloat(this.createForm.amount);
        this.createForm.memberId = this.createForm?.memberId?.id;
        this.createForm.credit_before = parseFloat(this.createForm.credit_before); 
        this.createForm.credit_after = parseFloat(this.createForm.credit_after);
        !this.editID
          ? this.$emit("onSubmitWithdraw", this.createForm)
          : this.$emit("onUpdateWithdraw", this.createForm);

        this.onClose();
        this.confirmCreateDialog = false;
      } else {
        this.confirmCreateDialog = false;
      }
    },
    onClose() {
      this.$emit("onClose", false);
    }
  },
  watch: {
    memberSearch(value) {
      this.$emit("memberSearch", value);
    }
  },
  computed: {
    creditRules() {
      if (this.createForm.memberId && !this.editID) {
        return [
          v => !!v || "*กรุณากรอกข้อมูล",
          v =>
            parseFloat(v) <= parseFloat(this.createForm.memberId.credit) ||
            "*เครดิตไม่เพียงพอ"
        ];
      } else {
        return [v => !!v || "*กรุณากรอกข้อมูล"];
      }
    },
    createDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("onClose", value);
        this.$refs.form.resetValidation();
      }
    },
    members() {
      return this.memberItems.map(item => {
        return Object.assign({}, item);
      });
    }
  }
};
</script>
