<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12"
                      ><v-text-field
                        outlined
                        v-model="params.search"
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>

            <v-expansion-panels class="my-4" flat>
              <v-expansion-panel>
                <v-expansion-panel-header @click="clearSearch"
                  >ค้นหาเพิ่มเติม</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row align="center">
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="จำนวน"
                        v-model="params.amount"
                        outlined
                        type="number"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="เลขธุรกรรมสมาชิก"
                        v-model="params.member_id"
                        outlined
                        type="number"
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="Username สมาชิก"
                        v-model="params.member_username"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-text-field
                        label="ชื่อสมาชิก"
                        v-model="params.member_name"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="ประเภท transaction"
                        v-model="params.transaction_type"
                        outlined
                        dense
                        hide-details
                        item-text="label"
                        item-value="value"
                        :items="selectOptions.transaction_type"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="สถานะ"
                        v-model="params.status"
                        outlined
                        dense
                        hide-details
                        :items="selectOptions.status"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="4" sn="4" md="4">
                      <v-select
                        label="Active"
                        v-model="params.is_active"
                        outlined
                        dense
                        hide-details
                        :items="selectOptions.isActive"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" lg="3" md="3" sm="3">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="3" md="3" sm="3">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <DataTable
              :headers="headers"
              :desserts="desserts"
              :meta="meta"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar
            flat
            dark
            dense
            color="transparent"
            width="100%"
            max-height="50px"
          >
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span>ทรานเฟอร์อิน</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form lazy-validation ref="form">
                <v-row no-gutters>
                  <v-col col="6" v-if="transferData.memberProvider">
                    <v-card color="#4E00AE" class="lg:my-2" dark flat>
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col>
                            <p class="ma-0 pa-0 font-bold text-lg">
                              {{
                                transferData.memberProvider.provider_username
                              }}
                            </p>
                            <p class="ma-0 pa-0">
                              {{ transferData.memberProvider.provider.name }}
                            </p>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col class="text-right">
                            <v-icon color="success">
                              mdi mdi-check-circle
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12">
                    <p class="pa-0 ma-0 text-white">เครดิต</p>
                    <v-text-field
                      :rules="transferRules"
                      v-bind="textFieldProp"
                      v-model="transferForm.amount"
                      dark
                      type="number"
                    ></v-text-field>

                    <div v-if="transferForm.member" class="mb-4">
                      <p class="ma-0 pa-0 font-bold text-white text-lg">
                        เครดิตปัจจุบัน : {{ transferForm.member.credit }}
                      </p>
                    </div>
                  </v-col>

                  <v-col>
                    <v-btn
                      block
                      large
                      dark
                      outlined
                      depressed
                      @click="onUpdateTransferIn"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable";
import TransactionService from "../../services/transaction.service";
import MemberService from "../../services/member.service";

import MemberProviderService from "./../../services/member-provider.service";
import BonusService from "./../../services/bonus.service";

export default {
  data() {
    return {
      headers: [
        { text: "รหัสธุรกรรม", value: "id" },
        { text: "Username", value: "member.username" },
        { text: "Name", value: "member.name" },
        // { text: "Bank", value: "member.bank" },
        // { text: "Account number", value: "member.account_number" },
        { text: "ผู้ให้บริการ", value: "memberProvider.provider.description" },
        {
          text: "Provider username",
          value: "memberProvider.provider_username"
        },
        { text: "Amount", value: "amount" },
        { text: "จำนวน Bonus ", value: "bonus_amount" },
        { text: "Status", value: "status" },
        { text: "สร้างโดย", value: "create_by.name" },
        { text: "เวลาทำรายการ", value: "created_at" },
        // { text: "แก้ไขโดย", value: "update_by.name" },
        // { text: "เวลาแก้ไขล่าสุด", value: "updated_at" },
        { text: "User/Member", value: "type_of_created" }
        // { text: "เวลา SMS", value: "sms_time" }
        // { text: "Actions", value: "actions" }
      ],
      desserts: [],
      meta: {},
      editID: null,
      drawer: false,
      memberItems: [],
      transferForm: {
        memberId: 0,
        amount: 0,
        credit_before: 0,
        credit_after: 0,
        bonusId: 0,
        is_active: true,
        status: "pending",
        transaction_type: "transferin",
        type_of_created: "user",
        use_api: false
      },
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        required: true,
        backgroundColor: "#5505AF"
      },

      providers: [],
      bonus: [],
      transferData: [],
      params: {
        page: 1,
        limit: 10,
        search: null,
        amount: null,
        is_active: null,
        status: null,
        type_of_created: null,
        member_id: null,
        member_username: null,
        member_name: null,
        transaction_type: "transferin"
      },
      selectOptions: {
        transaction_type: [
          {
            label: "ทรานเฟอร์อิน",
            value: "transferin"
          }
        ],
        isActive: [true, false],
        typeOfCreated: ["user", "member", "system"],
        status: ["pending", "done", "failure"]
      },
      panel: false
    };
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },
    clearSearch() {
      this.params.search = null;
    },
    async findAll(options) {
      const response = await TransactionService.findAll(options);
      if (response.status === 200) {
        this.desserts = response.data.items;
        this.meta = response.data.meta;
      }
    },

    async memberSearch(username) {
      const memberData = await MemberService.list(username);
      if (memberData.status === 200) {
        this.memberItems = memberData.data;
      }
    },
    async onUpdateTransferIn() {
      if (this.$refs.form.validate()) {
        const values = this.transferForm;
        const formBody = {
          memberId: values.memberId,
          amount: parseFloat(values.amount),
          credit_before: parseFloat(values.credit_before),
          credit_after: parseFloat(values.credit_after),
          bonusId: values.bonusId,
          description: values.description,
          is_active: values.is_active,
          status: values.status
        };

        const transferin = await TransactionService.update(
          this.editID,
          formBody
        );
        if (transferin.status === 200) {
          this.editID = null;
          this.clearForm();
          this.drawer = false;

          this.findAll(this.params);
          this.$store.commit("notification/show", {
            type: "success",
            text: "แก้ไขทรานเฟอร์อินเสร็จสิ้น"
          });
        } else {
          this.$store.commit("notification/show", {
            type: "error",
            text: "ไม่สามารถแก้ไขทรานเฟอร์อินได้ กรุณาลองอีกครั้ง!!"
          });
        }
      }
    },
    editItem(value) {
      if (!value) return false;

      this.editID = value.id;
      this.transferForm = this.lodash.cloneDeep(value);

      this.transferData = value;
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const { id } = value;
      const deleted = await TransactionService.delete(id);

      if (deleted.status === 200) {
        this.findAll(this.params);
      }
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    clearForm() {
      this.transferForm = this.$options.data.call(this).transferForm;
    },
    async findMemberProvider(id) {
      const memberProviderData = await MemberProviderService.findMember(id);

      if (memberProviderData.status !== 200) return false;
      this.providers = memberProviderData.data;
    },
    async findAllBonus() {
      const bonusData = await BonusService.findAll({
        page: 1,
        limit: 10,
        search: null
      });

      if (bonusData.status !== 200) return false;

      this.bonus = bonusData.data.items;
    }
  },
  watch: {
    async drawer(value) {
      if (!value) {
        this.clearForm();
      }
    },
    "params.search"(value) {
      if (value) this.panel = undefined;
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    },
    panel(status) {
      if (status !== undefined) {
        this.params = this.$options.data.call(this).params;
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));

    this.sockets.subscribe("getSocketNotificationAdminTransferin", response => {
      if (response === "fetchTransferin") {
        this.findAll(this.params);
      }
    });
  },
  computed: {
    transferRules() {
      if (this.transferForm.memberId) {
        return [
          v => !!v || "*กรุณากรอกข้อมูล",
          v =>
            parseFloat(v) <= parseFloat(this.transferForm.member.credit) ||
            "*เครดิตไม่เพียงพอ"
        ];
      } else {
        return [v => !!v || "*กรุณากรอกข้อมูล"];
      }
    }
  },
  components: {
    DataTable
  }
};
</script>
