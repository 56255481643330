<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto"
        ><v-btn color="primary" @click="drawer = true">
          <v-icon> mdi mdi-plus </v-icon> สร้างม้า</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="ค้นหา"
                        v-model="params.search"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-expansion-panels  class="my-4" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >ค้นหาเพิ่มเติม</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="ชื่อม้า"
                              v-model="params.horse_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="ชื่อคอก"
                              v-model="params.horse_owner"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn color="purple" block dark @click="search">
                              <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                            >
                          </v-col>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn
                              elevation="2"
                              color="purple"
                              block
                              outlined
                              class="mx-1"
                              @click="clear"
                            >
                              <v-icon>mdi-sync</v-icon>
                              เคลียร์
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <DataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @editItem="editItem"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      temporary
      class="h-fll bg-black bg-gradient-purple"
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar flat dark dense color="transparent" max-height="50px">
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            สร้างม้า
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form class="ma-0 pa-0" ref="form" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">ชื่อ</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="formData.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      รายละเอียด
                    </p>
                    <v-textarea
                      dark
                      rows="4"
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="formData.description"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      เจ้าของม้า
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="formData.owner"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      น้ำหนัก
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="weightRules"
                      v-model="formData.weight"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      น้ำหนักแปรผัน
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="weightRules"
                      v-model="formData.weight_variable"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-6">
                    <v-btn
                      block
                      large
                      outlined
                      dark
                      @click="validate"
                      depressed
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span v-if="!editID">สร้างม้า</span>
            <span v-else>แก้ไขม้า</span>
          </h1>
          <p class="text-sm font-light">
            <span v-if="!editID"> คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span>
            <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmCreateDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmit">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import DataTable from "@/components/tables/DataTable";
export default {
  data() {
    return {
      drawer: false,
      confirmCreateDialog: false,
      nameRules: [(v) => !!v || "*กรุณากรอกข้อมูล"],
      weightRules: [
        (v) => !!v || "*กรุณากรอกข้อมูล",
        (v) => v > 0 || "น้ำหนักไม่ถูกต้อง"
      ],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      editID: null,
      formData: {
        name: "",
        description: "",
        owner: "",
        weight: 0,
        weight_variable: 0
      },
      jockeyItems: [],
      jockeySearch: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "ชื่อม้า", value: "name" },
        { text: "ชื่อคอก", value: "owner" },
        { text: "น้ำหนัก", value: "weight" },
        { text: "น้ำหนักแปรผัน", value: "weight_variable" },
        { text: "Actions", value: "actions" }
      ],
      meta: {},
      desserts: [],
      params: {
        limit: 10,
        page: 1,
        search: null,
        horse_name: "",
        horse_owner: ""
      }
    };
  },
  watch: {
    drawer(value) {
      !value && this.resetForm();
    },
    jockeySearch(value) {
      this.findJockey(value);
    },
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },
    resetForm() {
      this.formData = this.$options.data.call(this).formData;
      this.editID = null;
      this.$refs.form.resetValidation();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.confirmCreateDialog = true;
      }
    },
    async onSubmit() {
      try {
        this.formData.weight = parseFloat(this.formData.weight);
        this.formData.weight_variable = parseFloat(
          this.formData.weight_variable
        );

        const { status } = !this.editID
          ? await HorseService.createHorse(this.formData)
          : await HorseService.updateHorse(this.editID, {
              name: this.formData.name,
              description: this.formData.description,
              owner: this.formData.owner,
              weight: Number(this.formData.weight),
              weight_variable: Number(this.formData.weight_variable),
              jokeyId: Number(this.formData.jokeyId)
            });

        if (status === 201 || status === 200) {
          this.drawer = false;
          this.confirmCreateDialog = false;
          this.resetForm();

          this.findAll(this.params);
        }
      } catch (error) {
        console.log(error);
      }
    },
    editItem(value) {
      if (!value) return false;

      this.editID = value.id;
      this.formData = this.lodash.cloneDeep(value);
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const deleted = await HorseService.deleteHorse(value.id);

      if (deleted.status === 200) {
        this.findAll(this.params);
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findAll(query) {
      try {
        const { data, status } = await HorseService.findAllHorse(query);

        if (status === 200) {
          this.desserts = data.items;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async findJockey(value) {
      const { data, status } = await HorseService.listJockey(value);

      if (status === 200) this.jockeyItems = data;
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  components: {
    DataTable
  }
};
</script>
