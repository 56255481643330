import { backendAPI } from "./../utils/axios";

const prefix = "/provider/";

class ProviderService {
  async findAll(params) {
    const response = await backendAPI.get(`${prefix}`, { params });

    return response;
  }

  async list() {
    const response = await backendAPI.get(`${prefix}list`);

    return response;
  }

  async find(id) {
    const response = await backendAPI.get(`${prefix}${id}`);

    return response;
  }

  async create(data) {
    const response = await backendAPI.post(`${prefix}`, data);

    return response;
  }

  async update(id, data) {
    const response = await backendAPI.put(`${prefix}${id}`, data);

    return response;
  }
  async delete(id) {
    const response = await backendAPI.delete(`${prefix}${id}`);

    return response;
  }
}

export default new ProviderService();
