<template>
  <div>
    <v-card>
      <v-card-title class="bg-green white--text "> Approve </v-card-title>
      <v-card-text>
        <ApproveDataTable
          :headers="headers"
          :desserts="desserts"
          :meta="meta"
          :bonusItems="bonusItems"
          transactionType="เติมเครดิต"
          @deleteItem="deleteItem"
          @approveItem="approveItem"
          @selectPage="selectPage"
          @setCurrentPage="setCurrentPage"
        />
      </v-card-text>
    </v-card>
    <MemberDetailDialog
      :dialog="approveMessageDialog"
      :memberData="memberData"
      @onClose="value => (approveMessageDialog = value)"
    />
  </div>
</template>

<script>
import ApproveDataTable from "@/components/tables/ApproveDatatable";
import MemberDetailDialog from "./../dialogs/MemberDetailDialog";
import TransactionService from "./../../../../services/transaction.service";
import BonusService from "./../../../../services/bonus.service";
export default {
  props: {
    fetchData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        { text: "ธุรกรรม", align: "center", value: "id" },
        { text: "Username", align: "center", value: "member.username" },
        { text: "ชื่อ", align: "center", value: "member.name" },
        { text: "ธนาคาร", align: "center", value: "member.bank" },
        {
          text: "เลขบัญชี",
          align: "center",
          value: "member.account_number"
        },
        { text: "จำนวน", align: "center", value: "amount" },
        { text: "โบนัส ", align: "center", value: "bonus" },
        { text: "เวลา SMS", align: "center", value: "sms_time" },
        { text: "หมายเหตุ", align: "center", value: "description" },
        { text: "Created At", value: "created_at" },
        { text: "Actions", align: "center", value: "actions" }
      ],
      desserts: [],
      meta: {},
      approveMessageDialog: false,
      memberData: null,
      params: {
        limit: 10,
        page: 1,
        transaction_type: "deposit"
      },
      bonusItems: []
    };
  },
  methods: {
    async findDeposit(query) {
      const { data, status } = await TransactionService.findDepositPending(
        query
      );
      if (status === 200) {
        this.desserts = data.items;
        this.meta = data.meta;
      }
    },
    onCopy(value) {
      this.$copyText(value).then(
        function(e) {
          alert("Copied");
          console.log(e);
        },
        function(e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    async approveItem(value) {
      const { id } = value;
      const { data, status } = await TransactionService.approveDepositAuto(
        id,
        value.bonus
      );
      if (status === 200) {
        this.memberData = data;
        this.approveMessageDialog = true;

        this.$socket.emit("setSocketNotificationAdminApproveDeposit");
        this.$emit("approveStatus", "success");
        this.findDeposit(this.params);
      }
    },
    async deleteItem(value) {
      if (!value) return false;
      const { id } = value;
      const deleted = await TransactionService.delete(id);

      if (deleted.status === 200) {
        this.findDeposit(this.params);
      }
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findBonus() {
      const { data, status } = await BonusService.list();

      if (status === 200) {
        this.bonusItems = data;
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.findDeposit(this.params));
    this.findBonus();
    this.sockets.subscribe(
      "getSocketNotificationAdminApproveDeposit",
      response => {
        if (response === "fetchApproveDeposit") this.findDeposit(this.params);
      }
    );
  },
  watch: {
    fetchData(value) {
      value && this.findDeposit(this.params);
    }
  },
  components: {
    ApproveDataTable,
    MemberDetailDialog
  }
};
</script>
