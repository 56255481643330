<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="permission(roleOptions.delete) && checkScope('delete')"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.data_bet`]="{ item }">
        {{ item | betResult }}
      </template>
      <template v-slot:[`item.result`]="{ item }">
        {{ item.result | resultFilter }}
      </template>
      <template v-slot:[`item.match`]="{ item }">
        {{ item | priceResult }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">ลบ</h1>
          <p class="text-sm font-light">คุณต้องการที่จะลบรายการนี้ หรือไม่?</p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmDeleteDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="comfirmDelete">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "./../../utils/role";
import moment from "moment";

export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    },
    scope: {
      type: Array,
      default: () => ["edit", "delete"]
    }
  },
  data() {
    return {
      deleteValue: [],
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  filters: {
    betResult(values) {
      const { match, data_bet } = values;
      let results = [];
      if (match) {
        data_bet.map(item => {
          const dataStamp = match.data_stamp.find(
            ({ horseId }) => Number(horseId) === item
          );

          const dataMatch = match.data_match.find(
            ({ horseId }) => Number(horseId) === item
          );
          if (!dataStamp) return;

          results = [
            ...results,
            `NO.${dataMatch.number} ${dataStamp.horseName}`
          ];
        });
      }

      return results.join(",");
    },
    priceResult(values) {
      const { match, data_bet, type_of_bet } = values;
      let results = [];
      if (match) {
        if (type_of_bet === "win" || type_of_bet === "place") {
          data_bet.map(item => {
            const matchData = match.data_match.find(
              ({ horseId }) => Number(horseId) === item
            );
            if (!matchData) return;

            results = [
              ...results,
              ` ${
                type_of_bet === "win"
                  ? matchData.winPrice
                  : matchData.placePrice
              }`
            ];
          });
        } else if (type_of_bet === "trifecta") {
          results = [match.trifecta_price];
        } else if (type_of_bet === "quinella") {
          results = [match.quinella_price];
        }
      }

      return results.join(",") || 0;
    },
    resultFilter(value) {
      return value === "win"
        ? "ชนะ"
        : value === "pending"
        ? "รอ"
        : value === "cancel"
        ? "ยกเลิก"
        : value === "lose"
        ? "แพ้"
        : "";
    }
  },
  methods: {
    moment,
    checkScope(value) {
      return !!this.scope.find(item => item === value);
    },
    editItem(value) {
      this.$emit("editItem", value);
    },
    deleteItem(value) {
      this.confirmDeleteDialog = true;
      this.deleteValue = value;
    },
    comfirmDelete() {
      this.$emit("deleteItem", this.deleteValue);
      this.confirmDeleteDialog = false;
    },
    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
