<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          @click="editItem(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          class="mr-2"
          color="warning"
          @click="editWinPlace(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          mdi-cash
        </v-icon>
        <v-icon
          color="red"
          class="mr-2"
          @click="editPosition(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          mdi-numeric
        </v-icon>

        <v-btn
          color="success"
          class="mr-2"
          x-small
          depressed 
          @click="autoUpdatePriceMatchQuinTri(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          A
        </v-btn>

        <!-- <v-icon
          color="success"
          class="mr-2"
          @click="editMatchStatus(item)"
          v-if="permission(roleOptions.update) && checkScope('edit')"
        >
          mdi-check-outline
        </v-icon> -->
        <!-- <v-icon
          class="px-4"
          @click="deleteItem(item)"
          v-if="permission(roleOptions.delete) && checkScope('delete')"
        >
          mdi-delete
        </v-icon> -->
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.check`]="{ item }">
        <v-icon color="red" class="mr-2" @click="editCheck(item)">
          mdi-flag
        </v-icon>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip-group mandatory>
          <v-chip
            class="mx-1"
            :color="item.status === 'pending' ? '#6c757d' : ''"
            :textColor="item.status === 'pending' ? 'white' : ''"
            @click="editMatchStatus({ ...item, status: 'pending' })"
            small
            :disabled="
              item.status === 'open' ||
                item.status === 'close' ||
                item.status === 'done' ||
                item.status === 'cancel'
            "
            >Pending</v-chip
          >
          <v-chip
            class="mx-1"
            @click="editMatchStatus({ ...item, status: 'open' })"
            :color="item.status === 'open' ? '#28a745' : ''"
            :textColor="item.status === 'open' ? 'white' : ''"
            small
            :disabled="
              item.status === 'close' ||
                item.status === 'done' ||
                item.status === 'cancel'
            "
            >Open</v-chip
          >
          <v-chip
            class="mx-1"
            @click="editMatchStatus({ ...item, status: 'close' })"
            :color="item.status === 'close' ? '#ffc107' : ''"
            :textColor="item.status === 'close' ? 'white' : ''"
            small
            :disabled="
              item.status === 'pending' ||
                item.status === 'done' ||
                item.status === 'cancel'
            "
            >Close</v-chip
          >
          <v-divider vertical class="mx-2"></v-divider>
          <v-chip
            class="mx-0"
            @click="editMatchStatus({ ...item, status: 'done' })"
            :color="item.status === 'done' ? '#007bff' : ''"
            small
            :disabled="
              item.status === 'open' ||
                item.status === 'pending' ||
                item.status === 'cancel'
            "
            :textColor="item.status === 'done' ? 'white' : ''"
            >Done</v-chip
          >
          <v-divider vertical class="ml-2 mx-4"></v-divider>
          <v-chip
            class="mx-0"
            :color="item.status === 'cancel' ? '#dc3545' : ''"
            @click="editMatchStatus({ ...item, status: 'cancel' })"
            small
            :textColor="item.status === 'cancel' ? 'white' : ''"
            :disabled="item.status === 'close' || item.status === 'done'"
            >cancel</v-chip
          >
        </v-chip-group>

        <!-- <v-btn-toggle dense>
          <v-btn :color="item.status === 'pending' && 'success'" height="30">
            pending
          </v-btn>
          <v-btn :color="item.status === 'open' && 'success'" height="30"> open </v-btn>
          <v-btn :color="item.status === 'close' && 'success'" height="30"> close </v-btn>
          <v-btn :color="item.status === 'done' && 'success'" height="30"> done </v-btn>
          <v-btn :color="item.status === 'cancel' && 'success'" height="30">
            cancel
          </v-btn>
        </v-btn-toggle> -->
      </template>
      <template v-slot:[`item.auto_price`]="{ item }">
        <v-switch
          v-model="item.auto_price"
          @change="onSwitchMatchStatus(item)"
        ></v-switch> </template
      >auto_price
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">ลบ</h1>
          <p class="text-sm font-light">คุณต้องการที่จะลบรายการนี้ หรือไม่?</p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmDeleteDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="comfirmDelete">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- ConfirmMatch  -->

    <v-dialog v-model="confirmMatchDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span>แก้ไขสถานะแมทซ์</span>
          </h1>
          <p class="text-sm font-light">
            <span> คุณต้องการที่จะแก้ไขสถานะแมทซ์นี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn color="error" depressed text tile @click="onCloseMatch">
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmitMatch">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- End confirm match -->
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "../../utils/role";
export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    },
    scope: {
      type: Array,
      default: () => ["edit", "delete"]
    }
  },
  data() {
    return {
      deleteValue: [],
      matchValue: {},
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions,
      confirmMatchDialog: false
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  methods: {
    checkScope(value) {
      return !!this.scope.find(item => item === value);
    },
    editItem(value) {
      this.$emit("editItem", value);
    },
    editWinPlace(value) {
      this.$emit("editWinPlace", value);
    },
    editPosition(value) {
      this.$emit("editPosition", value);
    },
    editMatchStatus(value) {
      this.$emit("editMatchStatus", value);
    },
    autoUpdatePriceMatchQuinTri(value) {
      this.$emit("autoUpdatePriceMatchQuinTri", value);
    },
    deleteItem(value) {
      this.confirmDeleteDialog = true;
      this.deleteValue = value;
    },
    onSwitchMatchStatus(value) {
      this.confirmMatchDialog = true;
      this.matchValue = value;
    },
    onCloseMatch() {
      this.confirmMatchDialog = false;
      const indexData = this.desserts.findIndex(
        item => item.id === this.matchValue.id
      );
      this.desserts[indexData].auto_price = !this.matchValue?.auto_price;
    },
    onSubmitMatch() {
      this.$emit("onAutoPriceUpdate", this.matchValue);
      this.confirmMatchDialog = false;
    },
    comfirmDelete() {
      this.$emit("deleteItem", this.deleteValue);
      this.confirmDeleteDialog = false;
    },
    editCheck(value) {
      this.$emit("editCheck", value);
    },

    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
