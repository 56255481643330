<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn color="primary" @click="drawer = true">
          <v-icon> mdi mdi-plus </v-icon> สร้างแมทซ์
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-1">
        <v-card class="my-4 pt-4">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="py-1">
                <v-form>
                  <v-row>
                    <v-col cols="12" class="py-1">
                      <v-text-field
                        label="ค้นหา"
                        v-model="params.search"
                        outlined
                        dense
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-expansion-panels class="my-4" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >ค้นหาเพิ่มเติม</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="ชื่อแมทช์"
                              v-model="params.match_name"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-text-field
                              label="รายละเอียดแมทช์"
                              v-model="params.match_description"
                              outlined
                              dense
                              hide-details
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-select
                              label="Match สถานะ"
                              v-model="params.match_status"
                              outlined
                              dense
                              hide-details
                              :items="filterStatus"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" lg="4" sn="4" md="4">
                            <v-dialog
                              ref="matchStatusDialog"
                              v-model="matchStatusDialog"
                              :return-value.sync="params.match_date"
                              persistent
                              max-width="350"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="params.match_date"
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  dense
                                  hide-details
                                  outlined
                                  v-bind="{ attrs }"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="params.match_date"
                                scrollable
                                locale="th"
                                width="100%"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="matchStatusDialog = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.matchStatusDialog.save(
                                      params.match_date
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn color="purple" block dark @click="search">
                              <v-icon> mdi mdi-magnify</v-icon> ค้นหา
                            </v-btn>
                          </v-col>
                          <v-col cols="6" lg="4" md="4" sm="4">
                            <v-btn
                              elevation="2"
                              color="purple"
                              block
                              outlined
                              class="mx-1"
                              @click="clear"
                            >
                              <v-icon>mdi-sync</v-icon>
                              เคลียร์
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <MatchDataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @editItem="editItem"
              @editWinPlace="editWinPlace"
              @editPosition="editPosition"
              @deleteItem="deleteItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
              @onAutoPriceUpdate="onAutoPriceUpdate"
              @editMatchStatus="editMatchStatus"
              @editCheck="editCheck"
              @autoUpdatePriceMatchQuinTri="autoUpdatePriceMatchQuinTri"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      temporary
      class="h-fll bg-black bg-gradient-purple"
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12" class="py-1">
          <v-toolbar flat dark dense color="transparent" max-height="50px">
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            {{
              editWinPlaceStatus
                ? "อัพเดทราคา"
                : editPositionStatus
                ? "อัพเดทผลการแข่งขัน"
                : editID
                ? "แก้ไขแมทซ์"
                : "สร้างแมทซ์"
            }}
          </h1>

          <v-row no-gutters justify="center" class="pb-4">
            <v-col cols="12" lg="10">
              <v-form class="ma-0 pa-0" ref="form" lazy-validation>
                <v-row
                  no-gutters
                  v-show="!editWinPlaceStatus && !editPositionStatus"
                >
                  <v-col cols="12" class="py-1">
                    <p class="ma-0 pa-0 py-1 text-white font-light text-sm">
                      ชื่อ
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      hide-details="auto"
                      v-model="formData.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <p class="ma-0 pa-0 py-1 text-white font-light text-sm">
                      รายละเอียด
                    </p>
                    <v-textarea
                      dark
                      rows="2"
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="formData.description"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <p class="ma-0 pa-0 py-1 text-white font-light text-sm">
                      Link
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="formData.link"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pr-1">
                    <p class="ma-0 pa-0 py-1 text-white font-light text-sm">
                      วันที่
                    </p>
                    <v-dialog
                      ref="dateDialog"
                      v-model="dateDialog"
                      :return-value.sync="formData.date"
                      persistent
                      max-width="350"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formData.date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dark
                          :rules="nameRules"
                          required
                          v-bind="{ ...textFieldProp, attrs }"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formData.date"
                        scrollable
                        locale="th"
                        width="100%"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateDialog = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dateDialog.save(formData.date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6" class="pl-1">
                    <p class="ma-0 pa-0 py-1 text-white font-light text-sm">
                      สถานะ
                    </p>
                    <v-select
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      :items="statusItems"
                      v-model="formData.status"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <p class="ma-0 pa-0 py-1 text-white font-light text-sm">
                      ประกาศ
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="formData.publish"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-sheet
                  v-for="(item, index) in formData.data_match"
                  :key="index"
                  color="rgba(0,0,0,0.15)"
                  class="pa-4 py-2 mt-4 my-2 rounded-lg"
                >
                  <v-row no-gutters>
                    <v-col
                      :cols="editID ? '12' : '12'"
                      :lg="editID ? '12' : index === 0 ? '12' : '12'"
                    >
                      <v-row>
                        <v-row
                          no-gutters
                          v-show="!editWinPlaceStatus && !editPositionStatus"
                        >
                          <v-col cols="12" class="py-1">
                            <p
                              class="my-2 ma-1 pa-0 text-white font-light text-lg font-bold"
                            >
                              ม้าตัวที่ {{ index + 1 }}
                            </p>

                            <v-col cols="12" class="py-1">
                              <v-row>
                                <v-col cols="10">
                                  <v-autocomplete
                                    v-model="item.horseId"
                                    :search-input.sync="horseSearch[index]"
                                    :rules="nameRules"
                                    @keyup="e => listHorse(e.target.value)"
                                    cache-items
                                    :items="horseList"
                                    item-value="id"
                                    item-text="name"
                                    color="#fff"
                                    dark
                                    clearable
                                    label="เลือกม้า"
                                    v-bind="textFieldProp"
                                    :disabled="item.status === 'not_ready'"
                                  >
                                    <template v-slot:item="{ item, on, attr }">
                                      <!-- <span>{{ item.name }} </span> -->
                                      <span v-on="on" v-bind="attr"
                                        >{{ horseSelect(item.id) }}
                                      </span>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    depressed
                                    :disabled="item.status === 'not_ready'"
                                    large
                                    @click="drawerHorse = true"
                                    color="success"
                                    class="rounded-0"
                                  >
                                    <v-icon>mdi mdi-plus</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" class="py-1">
                              <p
                                class="ma-0 pa-0 py-1 text-white font-light text-sm"
                              >
                                คนขี่
                              </p>
                              <v-row>
                                <v-col cols="10">
                                  <v-autocomplete
                                    v-model="item.jokeyId"
                                    :disabled="item.status === 'not_ready'"
                                    :items="jockeyList"
                                    :rules="nameRules"
                                    :search-input.sync="jockeySearch[index]"
                                    @keyup="e => listJockey(e.target.value)"
                                    cache-items
                                    item-value="id"
                                    item-text="name"
                                    color="#fff"
                                    dark
                                    clearable
                                    label="คนขี่"
                                    v-bind="textFieldProp"
                                  >
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn
                                    depressed
                                    large
                                    @click="drawerJockey = true"
                                    :disabled="item.status === 'not_ready'"
                                    color="success"
                                    class="rounded-0"
                                  >
                                    <v-icon>mdi mdi-plus</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" class="py-1">
                              <v-row>
                                <v-col cols="6">
                                  <p
                                    class="ma-0 pa-0 py-1 text-white font-light text-sm"
                                  >
                                    เลขที่
                                  </p>
                                  <v-text-field
                                    dark
                                    :disabled="item.status === 'not_ready'"
                                    v-bind="textFieldProp"
                                    :rules="nameRules"
                                    v-model="item.number"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <p
                                    class="ma-0 pa-0 py-1 text-white font-light text-sm"
                                  >
                                    ซอง
                                  </p>
                                  <v-text-field
                                    dark
                                    :disabled="item.status === 'not_ready'"
                                    v-bind="textFieldProp"
                                    :rules="nameRules"
                                    v-model="item.sort"
                                  ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" >
                                  <v-row> -->
                                <v-col
                                  cols="12"
                                  class="mb-2"
                                  v-if="
                                    index + 1 === formData.data_match.length &&
                                      !editID
                                  "
                                >
                                  <v-btn
                                    depressed
                                    large
                                    :disabled="
                                      !item.horseId ||
                                        !item.jokeyId ||
                                        item.status === 'not_ready'
                                    "
                                    color="success"
                                    @click="addHorse"
                                    class="rounded-0"
                                  >
                                    <v-icon>mdi mdi-plus</v-icon>
                                  </v-btn>
                                  <v-btn
                                    depressed
                                    large
                                    color="error"
                                    :disabled="item.status === 'not_ready'"
                                    v-if="index !== 0"
                                    class="rounded-0"
                                    @click="removeHorse(index)"
                                  >
                                    <v-icon>mdi mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                                <v-col
                                  cols="3"
                                  class="mb-2"
                                  lg="2"
                                  v-else-if="
                                    index + 1 !== formData.data_match.length &&
                                      !editID
                                  "
                                >
                                  <v-btn
                                    depressed
                                    large
                                    :disabled="item.status === 'not_ready'"
                                    block
                                    color="error"
                                    class="rounded-0"
                                    @click="removeHorse(index)"
                                  >
                                    <v-icon>mdi mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                                <!-- </v-row>
                                </v-col> -->

                                <!-- Update -->
                                <v-col cols="6" v-if="editID" class="mb-4">
                                  <p
                                    class="ma-0 pa-0 py-1 text-white font-light text-sm"
                                  >
                                    สถานะม้า
                                  </p>
                                  <v-select
                                    dark
                                    :items="['ready', 'not_ready']"
                                    v-bind="textFieldProp"
                                    :background-color="
                                      item.status === 'ready'
                                        ? 'success'
                                        : 'error'
                                    "
                                    :rules="nameRules"
                                    v-model="item.status"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <!-- <v-divider class="bg-white" v-if="editID"></v-divider> -->
                            </v-col>
                          </v-col>
                        </v-row>

                        <!-- Update -->
                        <v-col cols="12" v-show="editWinPlaceStatus">
                          <v-row no-gutters>
                            <v-col class="py-0">
                              <p class="ma-0 pa-0 text-white text-lg font-bold">
                                <v-chip
                                  class="ma-2"
                                  color="red"
                                  text-color="white"
                                  small
                                  v-if="item.status === 'not_ready'"
                                >
                                  NOT READY
                                </v-chip>
                                {{ horseName(item.horseId) }} ( เลขที่
                                {{ item.number }} )
                              </p>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col v-if="editID" class="py-0">
                              <v-row no-gutters>
                                <v-col class="py-0 mx-1">
                                  <p class="ma-0 pa-0 py-1 text-white text-sm">
                                    ราคา Win
                                  </p>
                                  <v-text-field
                                    dark
                                    v-bind="textFieldProp"
                                    v-model="item.winPrice"
                                    :disabled="item.status === 'not_ready'"
                                    hide-details
                                    type="number"
                                  ></v-text-field>
                                </v-col>

                                <v-col class="py-0 mx-1">
                                  <p
                                    class="ma-0 pa-0 py-1 text-white font-light text-sm"
                                  >
                                    ราคา Place
                                  </p>
                                  <v-text-field
                                    dark
                                    v-bind="textFieldProp"
                                    :disabled="item.status === 'not_ready'"
                                    type="number"
                                    hide-details
                                    class="pa-0 ma-0"
                                    v-model="item.placePrice"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-divider
                              inset
                              vertical
                              class="my-4 mt-6"
                            ></v-divider>
                            <v-col v-if="editID" class="py-0">
                              <v-row no-gutters>
                                <v-col class="py-0 mx-1">
                                  <p
                                    class="ma-0 pa-0 py-1 text-white font-light text-sm"
                                  >
                                    ยอดเงิน Win
                                  </p>
                                  <v-text-field
                                    dark
                                    hide-details
                                    v-bind="textFieldProp"
                                    v-model="item.totalBetWin"
                                    type="number"
                                    disabled
                                  ></v-text-field>
                                </v-col>

                                <v-col class="py-0 mx-1">
                                  <p
                                    class="ma-0 pa-0 py-1 text-white font-light text-sm"
                                  >
                                    ยอดเงิน Place
                                  </p>
                                  <v-text-field
                                    dark
                                    v-bind="textFieldProp"
                                    type="number"
                                    hide-details
                                    class="ma-0 pa-0"
                                    v-model="item.totalBetPlace"
                                    disabled
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <!-- <v-divider class="bg-white" v-if="editID"></v-divider> -->
                        </v-col>

                        <v-col cols="12" v-show="editPositionStatus">
                          <v-row no-gutters>
                            <v-col>
                              <p
                                class="mb-2 pa-0 text-white  text-lg font-bold"
                              >
                                <v-chip
                                  class="ma-2"
                                  color="red"
                                  text-color="white"
                                  small
                                  v-if="item.status === 'not_ready'"
                                >
                                  NOT READY
                                </v-chip>
                                {{ horseName(item.horseId) }} ( เลขที่
                                {{ item.number }} )
                              </p>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="6" class="px-1" v-if="editID">
                              <p
                                class="ma-0 pa-0 py-1 text-white font-light text-sm"
                              >
                                ตำแหน่ง ส
                              </p>
                              <v-text-field
                                dark
                                :disabled="item.status === 'not_ready'"
                                v-bind="textFieldProp"
                                v-model="item.positionS"
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-1" v-if="editID">
                              <p
                                class="ma-0 pa-0 py-1 text-white font-light text-sm"
                              >
                                ตำแหน่ง 3
                              </p>
                              <v-text-field
                                dark
                                :disabled="item.status === 'not_ready'"
                                v-bind="textFieldProp"
                                v-model="item.position3"
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-1" v-if="editID">
                              <p
                                class="ma-0 pa-0 py-1 text-white font-light text-sm"
                              >
                                ตำแหน่ง ต
                              </p>
                              <v-text-field
                                dark
                                :disabled="item.status === 'not_ready'"
                                v-bind="textFieldProp"
                                v-model="item.positionT"
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-1" v-if="editID">
                              <p
                                class="ma-0 pa-0 py-1 text-white font-light text-sm"
                              >
                                เวลาวิ่ง
                              </p>
                              <v-text-field
                                dark
                                :disabled="item.status === 'not_ready'"
                                v-bind="textFieldProp"
                                v-model="item.raceTime"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <!-- <v-divider class="bg-white" v-if="editID"></v-divider> -->
                          <v-row no-gutters>
                            <v-col cols="6" class="px-1" v-if="editID">
                              <p
                                class="ma-0 pa-0 py-1 text-white font-light text-sm"
                              >
                                อันดับเข้าเส้นชัย
                              </p>
                              <v-text-field
                                dark
                                :disabled="item.status === 'not_ready'"
                                v-bind="textFieldProp"
                                v-model="item.ranking"
                                type="number"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-divider />
                <v-card
                  v-show="editWinPlaceStatus"
                  color="rgba(243, 156, 18,0.8)"
                  class="my-4"
                  height="100"
                >
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <p class="ma-0 pa-0 text-white font-medium text-sm">
                          ราคา QUINELLA
                        </p>
                        <v-text-field
                          dark
                          v-bind="textFieldProp"
                          background-color="rgba(243, 156, 18,1)"
                          type="number"
                          v-model="formData.quinella_price"
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <p class="ma-0 pa-0 text-white font-medium text-sm">
                          ยอดเงิน QUINELLA
                        </p>
                        <v-text-field
                          dark
                          v-bind="textFieldProp"
                          background-color="rgba(243, 156, 18,1)"
                          type="number"
                          v-model="formData.total_bet_quinella"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card
                  v-show="editWinPlaceStatus"
                  color="rgba(192, 57, 43,0.8)"
                  class="my-4 pa-0"
                  height="100"
                >
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <p class="ma-0 pa-0 text-white font-medium text-sm">
                          ราคา TRIFECTA
                        </p>
                        <v-text-field
                          dark
                          v-bind="textFieldProp"
                          background-color="rgba(231, 76, 60,1.0)"
                          type="number"
                          v-model="formData.trifecta_price"
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <p class="ma-0 pa-0 text-white font-medium text-sm">
                          ยอดเงิน TRIFECTA
                        </p>
                        <v-text-field
                          dark
                          v-bind="textFieldProp"
                          background-color="rgba(231, 76, 60,1.0)"
                          type="number"
                          v-model="formData.total_bet_trifecta"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- <v-divider class="bg-white" v-if="editID"></v-divider> -->

                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-6">
                    <v-btn
                      block
                      large
                      outlined
                      dark
                      @click="validate"
                      depressed
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <CreateHorse
        :drawer="drawerHorse"
        @drawer="v => (drawerHorse = v)"
        @callback="v => v === 'success' && this.listHorse()"
      />
      <CreateJockey
        :drawer="drawerJockey"
        @drawer="v => (drawerJockey = v)"
        @callback="v => v === 'success' && this.listJockey()"
      />
    </v-navigation-drawer>

    <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span v-if="!editID">สร้างแมทซ์</span>
            <span v-else>แก้ไขแมทซ์</span>
          </h1>
          <p class="text-sm font-light">
            <span v-if="!editID"> คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span>
            <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmCreateDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmit">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmUpdateStatus" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span>แก้ไขสถานะแมทซ์</span>
          </h1>
          <p class="text-sm font-light">
            <span> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmUpdateStatus = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn
              color="success"
              text
              depressed
              tile
              @click="onSubmitMathStatus"
            >
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="matchResultStatus" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span>สรุป</span>
          </h1>

          <p class="text-base text-black  ">
            <span> ยอดรวม Win : {{ matchResultItem.total_win || 0 }}</span>
          </p>
          <p class="text-base text-black  ">
            <span> ยอดรวม Place: {{ matchResultItem.total_place || 0 }}</span>
          </p>
          <p class="text-base text-black  ">
            <span>
              ยอดรวม Quinella : {{ matchResultItem.total_quinella || 0 }}</span
            >
          </p>
          <p class="text-base text-black  ">
            <span>
              ยอดรวม Trifecta : {{ matchResultItem.total_trifecta || 0 }}</span
            >
          </p>
          <v-divider></v-divider>
          <p class="text-base text-black pt-4  ">
            <span>
              ยอดรวมแมทซ์ : {{ matchResultItem.total_of_match || 0 }}</span
            >
          </p>
        </v-card-text>
        <v-row justify="end">
          <v-col cols="auto" class="pa-4 px-6">
            <v-btn color="error" depressed @click="matchResultStatus = false">
              ปิด
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HorseService from "./../../services/horse.service";
import MatchDataTable from "@/components/tables/MatchDataTable";
import CreateHorse from "@/components/CreateHorse";
import CreateJockey from "@/components/CreateJockey";
import { mapActions } from "vuex";

// import union from "lodash/union";
export default {
  data() {
    return {
      drawer: false,
      drawerHorse: false,
      drawerJockey: false,
      confirmCreateDialog: false,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      confirmUpdateStatus: false,
      matchStatusDialog: false,
      matchResultStatus: false,
      matchResultItem: [],
      weightRules: [
        v => !!v || "*กรุณากรอกข้อมูล",
        v => v > 0 || "น้ำหนักไม่ถูกต้อง"
      ],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF",
        hideDetails: "auto"
      },
      editID: null,
      editWinPlaceStatus: false,
      editPositionStatus: false,
      formData: {
        name: "",
        description: "",
        data_stamp: [],
        quinella_price: 0,
        trifecta_price: 0,
        total_bet_quinella: 0,
        total_bet_trifecta: 0,
        link: "",
        data_match: [
          {
            horseId: null,
            jokeyId: null,
            number: 0,
            sort: 0,
            status: "ready",
            winPrice: 999,
            placePrice: 999,
            totalBetWin: 0,
            totalBetPlace: 0,
            raceTime: "",
            positionS: 0,
            position3: 0,
            positionT: 0,
            ranking: 0
          }
        ],
        date: new Date().toISOString().substr(0, 10),
        status: "pending",
        publish: ""
      },
      dateDialog: false,
      statusItems: ["pending", "open", "close", "cancel", "done"],
      horseItems: [],
      horseSearch: [],
      jockeyItems: [],
      jockeySearch: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "ชื่อ", value: "name" },
        { text: "รายละเอียด", value: "description" },
        { text: "วันที่", value: "date" },
        { text: "Auto price", value: "auto_price" },
        { text: "สถานะ", value: "status" },
        { text: "สรุป", value: "check" },
        { text: "Actions", value: "actions", width: 180 }
      ],
      meta: {},
      desserts: [],
      filterStatus: ["pending", "open", "close", "cancel", "done"],
      params: {
        limit: 10,
        page: 1,
        search: null,
        match_name: "",
        match_description: "",
        match_status: "",
        match_date: ""
      }
    };
  },

  watch: {
    drawer(value) {
      !value && this.resetForm();

      this.listHorse();
      this.listJockey();
    },
    "params.search"(value) {
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAll(this.params);
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },
    horseName(horseId) {
      if (!this.editID) return;
      const data = this.formData.data_stamp.find(
        item => item.horseId === horseId
      );

      return data?.horseName;
    },
    resetForm() {
      this.formData = this.$options.data.call(this).formData;
      this.$refs.form.resetValidation();
      this.editID = null;
      this.editWinPlaceStatus = false;
      this.editPositionStatus = false;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.confirmCreateDialog = true;
      }
    },
    async onSubmit() {
      try {
        const { status, data } = !this.editID
          ? await HorseService.createMatch(this.formData)
          : await HorseService.updateMatch(this.editID, this.formData);

        if (status === 201 || status === 200) {
          this.drawer = false;
          this.confirmCreateDialog = false;
          this.resetForm();

          this.$socket.emit("matchRoom", String(data.id));
          this.$socket.emit("openMatch", "openMatch");

          this.findAll(this.params);
        }
      } catch (error) {
        console.log(error);
      }
    },
    editItem(value) {
      if (!value) return false;
      this.editID = value.id;
      this.editWinPlaceStatus = false;
      this.editPositionStatus = false;

      this.formData = this.lodash.cloneDeep(value);
      this.formData.date = new Date(this.lodash.cloneDeep(value).date)
        .toISOString()
        .substr(0, 10);

      const { data_stamp } = this.formData;
      const horseResult = [];
      const jockeyResult = [];
      data_stamp.map(item => {
        horseResult.push({
          id: item.horseId,
          name: item.horseName,
          weight: item.horseWeight,
          weight_variable: item.horseWeightVariable,
          owner: item.horseOwner
        });

        jockeyResult.push({
          id: item.jokeyId,
          name: item.jokeyName
        });
      });
      this.horseItems = horseResult;
      this.jockeyItems = jockeyResult;
      this.drawer = true;
    },
    async onAutoPriceUpdate(value) {
      const { status } = await HorseService.updateMatch(value.id, value);
      if (status === 201 || status === 200) {
        this.drawer = false;
        this.confirmCreateDialog = false;
        this.resetForm();
        this.$socket.emit("matchRoom", String(value.id));

        this.findAll(this.params);
      }
    },
    editWinPlace(value) {
      if (!value) return false;

      this.editID = value.id;
      this.editWinPlaceStatus = true;
      this.editPositionStatus = false;

      this.formData = this.lodash.cloneDeep(value);
      this.formData.date = new Date(this.lodash.cloneDeep(value).date)
        .toISOString()
        .substr(0, 10);

      const { data_stamp } = this.formData;
      const horseResult = [];
      const jockeyResult = [];
      data_stamp.map(item => {
        horseResult.push({
          id: item.horseId,
          name: item.horseName,
          weight: item.horseWeight,
          weight_variable: item.horseWeightVariable,
          owner: item.horseOwner
        });

        jockeyResult.push({
          id: item.jokeyId,
          name: item.jokeyName
        });
      });

      this.horseItems = horseResult;
      this.jockeyItems = jockeyResult;
      this.drawer = true;
    },
    editPosition(value) {
      if (!value) return false;

      this.editID = value.id;
      this.editWinPlaceStatus = false;
      this.editPositionStatus = true;

      this.formData = this.lodash.cloneDeep(value);
      this.formData.date = new Date(this.lodash.cloneDeep(value).date)
        .toISOString()
        .substr(0, 10);

      const { data_stamp } = this.formData;
      const horseResult = [];
      const jockeyResult = [];
      data_stamp.map(item => {
        horseResult.push({
          id: item.horseId,
          name: item.horseName,
          weight: item.horseWeight,
          weight_variable: item.horseWeightVariable,
          owner: item.horseOwner
        });

        jockeyResult.push({
          id: item.jokeyId,
          name: item.jokeyName
        });
      });

      this.horseItems = horseResult;
      this.jockeyItems = jockeyResult;
      this.drawer = true;
    },
    async deleteItem(value) {
      if (!value) return false;
      const deleted = await HorseService.deleteMatch(value.id);

      if (deleted.status === 200) {
        this.findAll(this.params);
        this.$socket.emit("openMatch", "openMatch");
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    async findAll(query, showLoader = true) {
      try {
        const { data, status } = await HorseService.findAllMatch(
          query,
          showLoader
        );

        if (status === 200) {
          this.desserts = data.items;
          this.meta = data.meta;
        }
      } catch (error) {
        console.log(error);
      }
    },
    addHorse() {
      this.formData.data_match.push({
        horseId: null,
        jokeyId: null,
        number: 0,
        sort: 0,
        status: "ready",
        winPrice: 999,
        placePrice: 999,
        totalBetWin: 0,
        totalBetPlace: 0,
        raceTime: "",
        positionS: 0,
        position3: 0,
        positionT: 0,
        ranking: 0
      });
    },
    removeHorse(value) {
      const newDataMath = this.formData.data_match.filter(
        (_item, index) => index !== value
      );

      this.formData.data_match = newDataMath;
    },
    async listJockey(value) {
      const { data } = await HorseService.listJockey(value);

      this.jockeyItems = data;
    },
    async listHorse(value) {
      const { data, status } = await HorseService.listHorse(value);

      if (status === 200) {
        this.horseItems = data;
      }
    },
    clearHorse(item) {
      console.log(item);
    },
    horseSelect(value) {
      return this.horseItems.find(({ id }) => id === value).name;
    },
    editMatchStatus(value) {
      if (!value) return false;

      // this.editID = value.id;
      this.formData = this.lodash.cloneDeep(value);
      this.confirmUpdateStatus = true;
    },
    async onSubmitMathStatus() {
      try {
        if (this.formData.id) {
          const id = this.formData.id;

          const { status } = await HorseService.updateMatchStatusDone(
            id,
            this.formData.status
          );

          if (status !== 200) return;
          this.confirmUpdateStatus = false;
          this.resetForm();
          this.findAll(this.params);

          this.$socket.emit("openMatch", "openMatch");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async editCheck(value) {
      try {
        const { status, data } = await HorseService.matchResult(value.id);

        if (status !== 200) return;
        this.matchResultItem = data;
        this.matchResultStatus = true;
      } catch (error) {
        console.log(error.message);
      }
    },
    async autoUpdatePriceMatchQuinTri(value) {
      try {
        const { status } = await HorseService.autoUpdatePriceMatchQuinTri(
          value.id
        );

        if (status === 200) {
          this.alert({
            type: "success",
            text: "อัพเดทราคา Quinella,Trifecta เสร็จสิ้น"
          });
        } else {
          this.alert({
            type: "error",
            text: "อัพเดท Quinella,Trifecta ผิดพลาดหรุณาลองอีกครั้ง"
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    handleMessage(message) {
      if (message === "fetchBetAdmin") {
        this.findAll(this.params, false);
      } else if (message === "leftRoom") {
        alert(message);
      }
    },
    handleLeaveMessage(message) {
      console.log({ message });
    },
    ...mapActions({
      alert: "notification/show"
    })
  },
  created() {
    this.sockets.subscribe("getHorseBet", this.handleMessage);
  },

  mounted() {
    this.$nextTick(() => {
      this.findAll(this.params); 
    });
  },
  computed: {
    horseList() {
      const horseData = [];
      // console.log(this.formData.data_match);
      this.horseItems.map(item => {
        const horseSelected = !!this.formData.data_match.find(
          ({ horseId }) => horseId === item.id
        );
        !horseSelected && horseData.push(item);
      });

      return this.editID ? this.horseItems : horseData;
    },
    jockeyList() {
      const jockeyData = [];
      this.jockeyItems.map(item => {
        const jockeySelected = !!this.formData.data_match.find(
          ({ jokeyId }) => jokeyId === item.id
        );
        !jockeySelected && jockeyData.push(item);
      });

      return this.editID ? this.jockeyItems : jockeyData;
    }
  },
  components: {
    MatchDataTable,
    CreateHorse,
    CreateJockey
  }
};
</script>
