<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="redirectDetail(item)"> mdi-eye </v-icon>
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
    <!-- <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">ลบ</h1>
          <p class="text-sm font-light">คุณต้องการที่จะลบรายการนี้ หรือไม่?</p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn color="error" depressed text tile @click="confirmDeleteDialog = false">
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="comfirmDelete">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- ConfirmMatch  -->
    <!-- 
    <v-dialog v-model="confirmMatchDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span>แก้ไขสถานะแมทซ์</span>
          </h1>
          <p class="text-sm font-light">
            <span> คุณต้องการที่จะแก้ไขสถานะแมทซ์นี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn color="error" depressed text tile @click="onCloseMatch">
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmitMatch">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- End confirm match -->
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "../../utils/role";
import HorseService from "./../../services/horse.service";

import moment from "moment";
export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    },
    scope: {
      type: Array,
      default: () => ["edit", "delete"]
    }
  },
  data() {
    return {
      deleteValue: [],
      matchValue: {},
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions,
      confirmMatchDialog: false
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  watch: {
    expanded(value) {
      if (!value) return;
      this.expendData = [];
      this.findAll(value);
    }
  },
  methods: {
    async findAll(values) {
      try {
        try {
          const { data, status } = await HorseService.findAllMatch({
            page: 1,
            limit: 100,
            match_date: values[0].date
          });

          if (status === 200) {
            this.expendData = data.items;
            // this.meta = data.meta;
          }
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkScope(value) {
      return !!this.scope.find(item => item === value);
    },
    redirectDetail(values) {
      this.$router.push({
        path: "/partner/by-date",
        query: {
          date: moment(new Date(values.date)).format("L")
        }
      });
    },

    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
