<template>
  <v-row class="align-stretch d-flex">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-form>
                <v-row align="center">
                  <v-col cols="12"
                    ><v-text-field
                      outlined
                      v-model="params.search"
                      prepend-inner-icon="mdi mdi-magnify"
                      label="ค้นหา"
                      dense
                      hide-details
                    ></v-text-field
                  ></v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-expansion-panels  class="my-4" flat>
            <v-expansion-panel>
              <v-expansion-panel-header @click="clearSearch"
                >ค้นหาเพิ่มเติม</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row align="center">
                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-text-field
                      label="จำนวน"
                      v-model="params.amount"
                      outlined
                      type="number"
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-text-field
                      label="เลขธุรกรรมสมาชิก"
                      v-model="params.member_id"
                      outlined
                      type="number"
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-text-field
                      label="Username สมาชิก"
                      v-model="params.member_username"
                      outlined
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-text-field
                      label="ชื่อสมาชิก"
                      v-model="params.member_name"
                      outlined
                      dense
                      hide-details
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-select
                      label="ประเภท transaction"
                      v-model="params.transaction_type"
                      outlined
                      dense
                      hide-details
                      item-text="label"
                      item-value="value"
                      multiple
                      :items="selectOptions.transaction_type"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-select
                      label="สถานะ"
                      v-model="params.status"
                      outlined
                      dense
                      hide-details
                      :items="selectOptions.status"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="4" sn="4" md="4">
                    <v-select
                      label="Active"
                      v-model="params.is_active"
                      outlined
                      dense
                      hide-details
                      :items="selectOptions.isActive"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" lg="3" md="3" sm="3">
                    <v-btn color="purple" block dark @click="search">
                      <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                    >
                  </v-col>
                  <v-col cols="6" lg="3" md="3" sm="3">
                    <v-btn
                      elevation="2"
                      color="purple"
                      block
                      outlined
                      class="mx-1"
                      @click="clear"
                    >
                      <v-icon>mdi-sync</v-icon>
                      เคลียร์
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card width="100%" class="pa-2 rounded-lg">
        <!-- <v-row no-gutters>
          <v-col cols="9">
            <v-row>
              <v-col cols="auto">
                <v-menu
                  v-model="inputStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="เริ่มตั้น"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="inputStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="auto">
                <v-menu
                  v-model="inputEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="สิ้นสุด"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="inputEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="auto"
                ><v-text-field
                  v-model="search"
                  label="ค้นหา"
                  outlined
                  dense
                  prepend-icon="mdi-magnify"
                ></v-text-field
              ></v-col>
              <v-col align-self="auto" cols="auto">
                <v-btn elevation="2" color="purple" dark class="mx-1">
                  <v-icon>mdi-magnify</v-icon>
                  ค้นหา
                </v-btn>
                <v-btn elevation="2" color="purple" outlined class="mx-1">
                  <v-icon>mdi-sync</v-icon>
                  เคลียร์
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-card elevation="2" outlined>
              <v-card-text>
                <div>ยอดรวม</div>
                <p class="display-1 text--primary">
                  {{ total }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12">
            <TransactionDatatable
              :headers="headers"
              :desserts="desserts"
              :meta="meta"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import userService from "../../services/user.service";
import TransactionService from "./../../services/transaction.service";
import TransactionDatatable from "@/components/tables/TransactionDatatable";

export default {
  name: "Transaction",
  data: () => ({
    loading: false,
    startDate: null,
    inputStartDate: false,
    endDate: null,
    inputEndDate: false,
    total: "1,000,000,000",
    members: [],
    headers: [
      { text: "รหัสธุรกรรม", value: "id" },
      { text: "Username", value: "member.username" },
      { text: "Name", value: "member.name" },
      { text: "Bank", value: "member.bank" },
      { text: "Account number", value: "member.account_number" },
      { text: "Amount", value: "amount" },
      { text: "จำนวน Bonus ", value: "bonus_amount" },
      { text: "Transaction type", value: "transaction_type" }, 
      { text: "ผู้ให้บริการ", value: "memberProvider.provider.description" },
      { text: "เครดิตก่อน", value: "credit_before" },
      { text: "เครดิตหลัง", value: "credit_after" },
      { text: "สถานะ(ลบ)", value: "is_active" },
      { text: "User/Member", value: "type_of_created" },
      { text: "เวลา SMS", value: "sms_time" },
      { text: "สถานะ", value: "status" },
      { text: "สร้างโดย", value: "create_by.name" },
      { text: "เวลาทำรายการ", value: "created_at" },
      { text: "แก้ไขโดย", value: "update_by.name" },
      { text: "เวลาแก้ไขล่าสุด", value: "updated_at" }
    ],
    dialog: false,
    desserts: [],
    meta: [],
    params: {
      page: 1,
      limit: 10,
      search: null,
      amount: null,
      is_active: null,
      status: null,
      type_of_created: null,
      member_id: null,
      member_username: null,
      member_name: null,
      transaction_type: ["transferin", "transferout", "deposit", "withdraw" ,'updatecredit']
    },
    selectOptions: {
      transaction_type: [
        // {
        //   label: "ทั้งหมด",
        //   value: "transferin,transferout,deposit,withdraw"
        // },
        {
          label: "เติมเครดิต",
          value: "deposit"
        },
        {
          label: "ถอนเครดิต",
          value: "withdraw"
        },
        {
          label: "โยกเครดิตเข้า",
          value: "transferin"
        },
        {
          label: "โยกเครดิตออก",
          value: "transferout"
        },
        {
          label: "***อัพเดตเครดิต",
          value: "updatecredit"
        }
      ],
      isActive: [true, false],
      typeOfCreated: ["user", "member", "system"],
      status: ["pending", "done", "failure"]
    },
    panel: false
  }),

  mounted() {
    this.$nextTick(() => this.findAllTransaction(this.params));
  },
  watch: {
    "params.search"(value) {
      if (value) this.panel = undefined;
      this.params = this.$options.data.call(this).params;

      this.params.search = value;
      this.findAllTransaction(this.params);
    },
    panel(status) {
      if (status !== undefined) {
        this.params = this.$options.data.call(this).params;
      }
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.findAllTransaction(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAllTransaction(this.params);
    },
    clearSearch() {
      this.params.search = null;
    },
    getRequestParams(search, page, pageSize) {
      let params = {};
      if (search) {
        params["search"] = search;
      }
      if (page) {
        params["page"] = page;
      }
      if (pageSize) {
        params["limit"] = pageSize;
      }
      return params;
    },
    fetchTransactions() {
      this.loading = true;
      const params = this.getRequestParams(
        this.search,
        this.page,
        this.pageSize
      );
      userService.findAll(params).then(data => {
        this.members = data.items;
        this.totalPages = data.meta.totalPages;
        this.loading = false;
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.fetchAdmin();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.fetchAdmin();
    },
    async findAllTransaction(options) {
      const transaction_type = options.transaction_type.join();
      const transactionData = await TransactionService.findAll({
        ...options,
        transaction_type
      });

      if (transactionData.status === 200) {
        this.desserts = transactionData.data.items;
        this.meta = transactionData.data.meta;
      }
    },
    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAllTransaction(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAllTransaction(this.params);
      }
    }
  },
  components: {
    TransactionDatatable
  }
};
</script>
