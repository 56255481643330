<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="9" sm="9" md="9">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12" lg="8" sm="8" md="8"
                      ><v-text-field
                        outlined
                        v-model="params.search"
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <MemberProviderDatatable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @deleteItem="deleteItem"
              @editItem="editItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
              role="member"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar
            flat
            dark
            dense
            color="transparent"
            width="100%"
            max-height="50px"
          >
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span>แก้ไขสมาชิกของผู้ให้บริการ</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4 mt-6">
            <v-col cols="12" lg="10">
              <v-form
                class="ma-0 pa-0"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row no-gutters v-if="editID">
                  <v-col cols="12" class="px-2">
                    <p class="text-white font-bold text-xl">
                      Username : {{ formMemberProvider.member.username }}
                    </p>
                  </v-col>
                  <!-- <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Provider username
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formMemberProvider.provider_username"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      รหัสผ่าน
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formMemberProvider.provider_password"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      สถานะ
                    </p>
                    <v-switch v-model="formMemberProvider.is_active"></v-switch>
                  </v-col> -->
                </v-row>
                <!-- Action -->
                <v-row justify="start" v-if="drawer" class="px-3">
                  <v-col cols="12" lg="6" sm="6" md="6">
                    <v-btn
                      block
                      large
                      dark
                      outlined
                      depressed
                      @click="confirmCreateDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span>แก้ไขสมาชิกของผู้ให้บริการ</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MemberProviderDatatable from "@/components/tables/MemberProviderDatatable";
import MemberProvider from "./../../services/member-provider.service";
export default {
  data() {
    return {
      headers: [
        {
          text: "ธุรกรรม",
          align: "center",
          sortable: true,
          value: "id"
        },
        { text: "Username", value: "member.username" },
        { text: "Name", value: "member.name" },
        { text: "ผู้ให้บริการ", value: "provider.description" },
        { text: "Username ของผู้ให้บริการ", value: "provider_username" },
        { text: "Password ของผู้ให้บริการ", value: "provider_password" },
        { text: "Actions", value: "actions" }
      ],
      meta: {},
      desserts: [],
      editID: null,
      drawer: false,
      valid: false,
      confirmCreateDialog: false,
      params: {
        limit: 10,
        page: 1,
        search: null
      },
      birthDateDialog: false,
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      formMemberProvider: {
        provider_username: null,
        provider_password: null,
        is_active: true
      }
    };
  },
  watch: {
    drawer(value) {
      if (!value) {
        this.clearForm();
      }
    }
  },
  filters: {
    dateFormat(value) {
      return new Date(value).toISOString().substr(0, 10);
    }
  },
  methods: {
    async findAll(options) {
      try {
        const response = await MemberProvider.findAll(options);
        if (response.status === 200) {
          this.desserts = response.data.items;
          this.meta = response.data.meta;
        }
      } catch (error) {
        new Promise.reject(error);
      }
    },
    async deleteItem(value) {
      if (value) {
        const { id } = value;

        const deleted = await MemberProvider.delete(id);
        if (deleted.status === 200) {
          this.findAll(this.params);
          this.drawer = false;
          this.clearForm();
        }
      }
    },
    editItem(value) {
      if (value) {
        this.editID = value.id;
        this.formMemberProvider = this.lodash.cloneDeep(value); //cloneDeep : freeze data [disable two way data binding]
        this.drawer = true;

        this.$refs.form.resetValidation();
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    search() {
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },
    clearForm() {
      this.editID = null;
      this.formMemberProvider = {
        username: null,
        password: null,
        name: null,
        nickname: null,
        tel: null,
        birth_date: new Date().toISOString().substr(0, 10),
        picture: null,
        base_salary: 0,
        balance_salary: 0
      };

      this.$refs.form.resetValidation();
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        const updated = await MemberProvider.update(
          this.editID,
          this.formMemberProvider
        );

        if (updated.status === 200) {
          this.findAll(this.params);
          this.drawer = false;

          this.$store.commit("notification/show", {
            type: "success",
            text: "แก้ไขสมาชิกของผู้ให้บริการสำเร็จ"
          });
          this.clearForm();
        } else {
          this.$store.commit("notification/show", {
            type: "error",
            text: "แก้ไขสมาชิกของผู้ให้บริการไม่สำเร็จ"
          });
        }
      } else {
        this.confirmCreateDialog = false;
      }
    }
  },

  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  components: {
    MemberProviderDatatable
  }
};
</script>
