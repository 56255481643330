<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="9" sm="9" md="9">
            <v-form>
              <v-row align="center">
                <v-col cols="12" lg="8" sm="8" md="8"
                  ><v-text-field
                    outlined
                    prepend-inner-icon="mdi mdi-magnify"
                    label="ค้นหา"
                    dense
                    hide-details
                    v-model="params.search"
                  ></v-text-field
                ></v-col>
                <v-col cols="6" lg="auto" md="auto" sm="auto">
                  <v-btn color="purple" block dark @click="search()">
                    <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                  >
                </v-col>
                <v-col cols="6" lg="auto" md="auto" sm="auto">
                  <v-btn
                    elevation="2"
                    color="purple"
                    block
                    outlined
                    class="mx-1"
                    @click="clear()"
                  >
                    <v-icon>mdi-sync</v-icon>
                    เคลียร์
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="auto" sm="auto" md="auto">
            <v-btn
              block
              color="primary"
              class="font-weight-bold"
              @click="drawer = true"
            >
              <v-icon>mdi mdi-plus</v-icon>
              สร้างรีวอร์ด</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="my-2">
      <v-card-text>
        <DataTable
          :headers="headers"
          :desserts="desserts"
          :meta="meta"
          @deleteItem="deleteItem"
          @editItem="editItem"
          @selectPage="selectPage"
          @setCurrentPage="setCurrentPage"
        />
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar flat dark dense color="transparent" max-height="50px">
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span v-if="!editID">สร้างรีวอร์ด</span>
            <span v-else>แก้ไขรีวอร์ด</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form
                class="ma-0 pa-0"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row no-gutters>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">ชื่อ</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="rewardForm.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Size</p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="rewardForm.sub_title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      รายละเอียด
                    </p>
                    <v-textarea
                      rows="4"
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="rewardForm.description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      หมวดหมู่
                    </p>
                    <v-select
                      :items="['electronic', 'clothing', 'furniture', 'credit']"
                      dark
                      v-bind="textFieldProp"
                      :rules="nameRules"
                      v-model="rewardForm.type_of_reward"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      รูปภาพ
                    </p>
                    <v-img
                      v-if="!!imagePreview"
                      :src="imagePreview"
                      height="300"
                      class="w-full mb-4"
                      contain
                    ></v-img>
                    <v-file-input
                      truncate-length="15"
                      v-bind="textFieldProp"
                      :rules="!!imagePreview ? undefined : nameRules"
                      dark
                      v-model="rewardForm.image"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="6" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      จำนวนแต้มที่ใช้แลก
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="rewardForm.point"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      จำนวนของคงเหลือ
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="rewardForm.balance"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-4">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      จำนวนที่มีการแลกของไป
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="rewardForm.took_reward"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col cols="12" lg="6" sm="6" md="6" class="px-4">
                    <v-btn
                      block
                      large
                      outlined
                      dark
                      @click="confirmCreateDialog = true"
                      depressed
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span v-if="!editID">สร้างรีวอร์ด</span>
              <span v-else>แก้ไขรีวอร์ด</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable";
import rewardService from "./../../services/reward.service";
import FileService from "./../../services/file.service";

import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
export default {
  data() {
    return {
      valid: false,
      drawer: false,
      confirmCreateDialog: false,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      imageRules: [v => v.length <= 0 || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      params: {
        page: 1,
        limit: 10,
        search: null
      },
      headers: [
        {
          text: "ชื่อ",
          align: "start",
          value: "title"
        },
        { text: "จำนวนแต้มที่ใช้แลก", value: "point" },
        { text: "จำนวนคงเหลือ", value: "balance" },
        { text: "จำนวนที่แลก", value: "took_reward" },
        { text: "Created At", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      rewardForm: {
        title: "",
        sub_title: "",
        description: "",
        point: 0,
        took_reward: 0,
        balance: 0,
        image: null,
        type_of_reward: "",
        is_active: true
      },
      meta: {},
      desserts: [],
      editID: null,
      imagePreview: null
    };
  },
  methods: {
    async findAll(options) {
      try {
        const { data, status } = await rewardService.findAll(options);
        if (status !== 200) return false;

        this.desserts = data.items;
        this.meta = data.meta;
      } catch (error) {
        console.log(error);
      }
    },
    editItem(value) {
      this.drawer = true;
      this.editID = value.id;
      this.rewardForm = cloneDeep(value);

      if (this.validURL(value.image)) {
        this.rewardForm.image = null;
        this.imagePreview = value.image;
      }
    },
    async deleteItem(value) {
      try {
        const { status } = await rewardService.delete(value.id);
        if (status !== 200) return false;

        this.findAll(this.params);
      } catch (error) {
        console.log(error);
      }
    },
    search() {
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;
      this.findAll(this.params);
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    async createReward() {
      try {
        const data = this.rewardForm;

        const formData = new FormData();
        formData.append("file", data.image);

        const image = await FileService.upload(formData);
        if (image.status !== 201) return false;

        data.image = image.data.url;
        const response = await rewardService.create(data);

        if (response.status === 201) {
          this.findAll(this.params);
        }
      } catch (error) {
        new Promise.reject(error);
      }
    },
    async updateReward() {
      try {
        const formData = new FormData();
        let data = this.rewardForm;
        if (!this.imagePreview) {
          formData.append("file", data.image);
          const image = await FileService.upload(formData);

          if (image.status !== 201) return false;
          data.image = image.data.url;
        }
        if (!data.image) {
          data = omit(data, ["image"]);
        }

        const { status } = await rewardService.update(data.id, data);

        if (status === 200) this.findAll(this.params);
      } catch (error) {
        new Promise.reject(error);
      }
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.rewardForm.point = parseInt(this.rewardForm.point);
        this.rewardForm.took_reward = parseInt(this.rewardForm.took_reward);
        this.rewardForm.balance = parseInt(this.rewardForm.balance);

        if (!this.editID) {
          this.createReward(this.rewardForm);
          this.findAll(this.params);
        } else {
          this.updateReward(this.rewardForm);
          this.findAll(this.params);
        }

        this.confirmCreateDialog = false;
        this.drawer = false;
        this.$refs.form.resetValidation();
      }
    },

    selectPage(value) {
      if (value !== this.params.limit) {
        this.params.limit = value;
        this.params.page = 1;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value !== this.params.page) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    resetForm() {
      this.rewardForm = this.$options.data.call(this).rewardForm;
    }
  },

  watch: {
    drawer(value) {
      if (!value) {
        this.resetForm();
        this.imagePreview = null;
        this.editID = null;
      }
      this.$refs.form.resetValidation();
    },
    "rewardForm.image"(value) {
      if (typeof value === "object") {
        this.rewardForm.image = value;
        this.imagePreview = null;
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  components: {
    DataTable
  }
};
</script>
