<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="9" sm="9" md="9">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12" lg="8" sm="8" md="8"
                      ><v-text-field
                        outlined
                        v-model="params.search"
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" lg="auto" sm="auto" md="auto">
                <v-btn
                  block
                  color="primary"
                  class="font-weight-bold"
                  @click="drawer = true"
                >
                  <v-icon>mdi mdi-plus</v-icon>
                  สร้างผู้ให้บริการ</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <DataTable
              :meta="meta"
              :headers="headers"
              :desserts="desserts"
              @deleteItem="deleteItem"
              @editItem="editItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar
            flat
            dark
            dense
            color="transparent"
            width="100%"
            max-height="50px"
          >
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span v-if="!editID">สร้างผู้ให้บริการ</span>
            <span v-else>แก้ไขผู้ให้บริการ</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form
                class="ma-0 pa-0"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row no-gutters>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">ชื่อ</p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formProvider.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      รายละเอียด
                    </p>
                    <v-textarea
                      dark
                      rows="5"
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formProvider.description"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row justify="start" v-if="drawer" class="px-3">
                  <v-col cols="12" lg="6" sm="6" md="6">
                    <v-btn
                      block
                      large
                      dark
                      outlined
                      depressed
                      @click="confirmCreateDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span v-if="!editID">สร้างผู้ให้บริการ</span>
              <span v-else>แก้ไขผู้ให้บริการ</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable";
import ProviderService from "./../../services/provider.service";
export default {
  data() {
    return {
      valid: false,
      formProvider: {
        name: null,
        description: null,
        config: {},
        is_active: true
      },
      editID: null,
      drawer: false,
      confirmCreateDialog: false,
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      params: {
        search: null,
        limit: 10,
        page: 1
      },
      headers: [
        { text: "ชื่อ", value: "name" },
        { text: "รายละเอียด", value: "description" },
        { text: "Action", value: "actions" }
      ],
      meta: {},
      desserts: []
    };
  },
  watch: {
    drawer(value) {
      if (!value) {
        this.clearForm();
      }
    }
  },
  methods: {
    search() {
      this.params.page = 1;
      this.getAllProvider(this.params);
    },
    clearForm() {
      this.editID = null;
      this.formProvider = {
        name: null,
        description: null,
        config: {},
        is_active: true
      };

      this.$refs.form.resetValidation();
    },
    clear() {
      this.params = {
        search: null,
        limit: 10,
        page: 1
      };
    },
    async onSubmit() {
      try {
        if (this.$refs.form.validate()) {
          if (!this.editID) {
            const created = await ProviderService.create(this.formProvider);
            if (created.status === 201) {
              this.getAllProvider(this.params);
              this.drawer = false;
            }
          } else {
            if (this.drawer && this.editID) {
              const updated = await ProviderService.update(
                this.editID,
                this.formProvider
              );
              if (updated.status === 200) {
                this.getAllProvider(this.params);
                this.drawer = false;
              }
            }
          }
        } else {
          this.confirmCreateDialog = false;
        }
      } catch (error) {
        new Promise.reject(error);
      }
    },
    async createProvider(values) {
      try {
        const created = await ProviderService.create(values);
        if (created.status === 201) {
          this.getAllProvider(this.params);
          this.drawer = false;
        }
      } catch (error) {
        new Promise.reject(error);
      }
    },
    async getAllProvider(options) {
      try {
        const response = await ProviderService.findAll(options);

        this.desserts = response.data.items;
        this.meta = response.data.meta;
      } catch (error) {
        new Promise.reject(error);
      }
    },
    editItem(value) {
      this.editID = value.id;
      this.formProvider = this.lodash.cloneDeep(value); //cloneDeep : freeze data [disable two way data binding]
      this.drawer = true;
    },
    async deleteItem(value) {
      try {
        const { id } = value;
        const deleted = await ProviderService.delete(id);
        if (deleted.status === 200) {
          this.getAllProvider(this.params);
        }
      } catch (error) {
        new Promise.reject(error);
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.getAllProvider(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.getAllProvider(value);
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.getAllProvider(this.params));
  },
  components: {
    DataTable
  }
};
</script>
