<template>
  <v-bottom-sheet v-model="buttomDrawer" inset temporary>
    <v-sheet class="overflow-scroll">
      <v-card class="border-none " :color="!tab ? 'purple' : '#1B1E37'" tile>
        <v-card-text>
          <v-card
            :color="!tab ? 'purple' : '#1B1E37'"
            class="border-none"
            tile
            flat
            dark
          >
            <v-card-text class="ma-0 pa-0">
              <v-tabs
                fixed-tabs
                background-color="transparent"
                dark
                v-model="tab"
              >
                <v-tab v-for="(item, index) in tabItems" :key="index">
                  {{ item }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in tabItems" :key="item">
                  <v-card flat :color="!tab ? 'purple' : '#1B1E37'" tile dark>
                    <v-card-text
                      v-if="item === 'ทรานเฟอร์อิน'"
                      class="ma-0 pa-0"
                    >
                      <TransferInTab
                        :transferData="transferData"
                        :providers="providers"
                        :bonusItems="bonus"
                        :transferForm="transferForm"
                        @onSubmitTransferIn="onSubmitTransferIn"
                      >
                      </TransferInTab>
                    </v-card-text>
                    <v-card-text v-else class="ma-0 pa-0"
                      ><TransferOutTab
                        :transferData="transferData"
                        :transferForm="transferForm"
                        :providers="providers"
                        @onSubmitTransferOut="onSubmitTransferOut"
                    /></v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import TransferInTab from "./../tabs/TransferInTab";
import TransferOutTab from "./../tabs/TransferOutTab";
import TransactionService from "./../../../../services/transaction.service";
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    providers: {
      type: [Array, Object],
      default: () => []
    },
    transferData: {
      type: Object
    },
    bonus: {
      type: Array
    }
  },
  data() {
    return {
      title: "",
      tab: 0,
      isLoading: false,
      tabItems: ["ทรานเฟอร์อิน", "ทรานเฟอร์เอ้า"],
      bonusItems: [],
      bonusSearch: null,
      bonusParams: {
        limit: 10,
        page: 1,
        search: null
      },
      transferForm: {
        memberId: 0,
        memberProviderId: 0,
        amount: 0,
        bonusId: 0,
        description: null,
        is_active: true,
        status: "pending",
        transaction_type: "",
        type_of_created: "user",
        use_api: false
      }
    };
  },
  methods: {
    setProviderTransferIn(id) {
      this.transferForm.memberProviderId = id;
    },

    async onSubmitTransferOut(value) {
      this.transferForm.bonusId = this.transferForm.bonusId.id
      const created = await TransactionService.transactionOut(value);
      if (created.status === 201) {
        this.reset();
        this.$emit("onClose", false);
      }
    },
    async onSubmitTransferIn(value) {
      this.transferForm.bonusId = this.transferForm.bonusId.id
      const created = await TransactionService.transactionIn(value);
      if (created.status === 201) {
        this.reset();
        this.$emit("onClose", false);
      }
    },
    reset() {
      this.lodash.assign(this.$data, this.$options.data.call(this));
      this.tab = 0
    },
    clearForm() {
      this.transferForm = {
        memberId: 0,
        memberProviderId: 0,
        amount: 0,
        bonusId: 0,
        description: null,
        is_active: true,
        status: "pending",
        transaction_type: "",
        type_of_created: "user",
        use_api: false
      };
      
    }
  },
  watch: {
    bonusSearch(value) {
      if (value) {
        this.$emit("bonusSearch", value);
      }
    },
    drawer() {
      // if (!value) this.reset();
    },
    tab() {
      this.clearForm();
    }
  },
  computed: {
    buttomDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("onClose", value);
        this.reset();
      }
    }
  },

  components: {
    TransferInTab,
    TransferOutTab
  }
};
</script>
