var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":parseInt(_vm.meta.itemsPerPage),"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permission(_vm.roleOptions.update) && _vm.checkScope('edit'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.permission(_vm.roleOptions.delete) && _vm.checkScope('delete'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.data_bet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("betResult")(item))+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("resultFilter")(item.result))+" ")]}},{key:"item.match",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("priceResult")(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.created_at))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.date))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.updated_at))+" ")]}}],null,true)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"label":"Items per Page","items":_vm.pageSizes,"outlined":"","dense":""},model:{value:(_vm.itemPerPage),callback:function ($$v) {_vm.itemPerPage=$$v},expression:"itemPerPage"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{attrs:{"color":"purple","length":_vm.meta.totalPages,"total-visible":"5","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"320"},model:{value:(_vm.confirmDeleteDialog),callback:function ($$v) {_vm.confirmDeleteDialog=$$v},expression:"confirmDeleteDialog"}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-text',[_c('h1',{staticClass:"text-xl font-bold mt-4 mb-2"},[_vm._v("ลบ")]),_c('p',{staticClass:"text-sm font-light"},[_vm._v("คุณต้องการที่จะลบรายการนี้ หรือไม่?")])]),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"error","depressed":"","text":"","tile":""},on:{"click":function($event){_vm.confirmDeleteDialog = false}}},[_vm._v(" ยกเลิก ")]),_c('v-btn',{attrs:{"color":"success","text":"","depressed":"","tile":""},on:{"click":_vm.comfirmDelete}},[_vm._v(" ยืนยัน ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }