<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="parseInt(meta.itemsPerPage)"
      disable-pagination
      hide-default-footer
      :expanded.sync="expanded"
      :single-expand="true"
      show-expand
      item-key="index"
    >
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length" class="pa-4">
          <v-data-table
            :single-expand="true"
            :headers="expendHeaders"
            :items="expendData"
            :items-per-page="5"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.date | dateTimeLocal }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon class="mr-2" @click="redirectDetail(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimeLocal }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | dateTimeLocal }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | dateTimeLocal }}
      </template>
    </v-data-table>

    <v-col cols="12">
      <v-row justify="end">
        <v-col cols="auto">
          <v-select
            label="Items per Page"
            :items="pageSizes"
            v-model="itemPerPage"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-pagination
            color="purple"
            :length="meta.totalPages"
            v-model="page"
            total-visible="5"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-col>
     

    <!-- ConfirmMatch  -->
 

    <!-- End confirm match -->
  </div>
</template>

<script>
import { roleMenu, roleOptions, actionPermisstion } from "../../utils/role";
import HorseService from "./../../services/horse.service";

import moment from "moment-timezone";
export default {
  props: {
    headers: {
      type: Array
    },
    desserts: {
      type: Array
    },
    expanded: {
      type: Array
    },
    meta: {
      type: [Array, Object],
      default: () => ({
        currentPage: 1,
        itemCount: 10,
        itemsPerPage: 10,
        totalItems: 10,
        totalPages: 1
      })
    },
    role: {
      type: String,
      default: ""
    },
    scope: {
      type: Array,
      default: () => ["edit", "delete"]
    }
  },
  data() {
    return {
      deleteValue: [],
      matchValue: {},
      pageSizes: [10, 25, 50],
      confirmDeleteDialog: false,
      roleMenu,
      roleOptions,
      confirmMatchDialog: false,
      expendHeaders: [
        { text: "ID", value: "id" },
        { text: "วันที่", value: "date" },
        { text: "ชื่อ", value: "name" },
        { text: "รายละเอียด", value: "description" },
        { text: "ยอดรวมม้า Win", value: "total_bet_win" },
        { text: "ยอดรวมม้า Place", value: "total_bet_place" },
        { text: "ยอดรวมม้า Quinella", value: "total_bet_quinella" },
        { text: "ยอดรวมม้า Trifecta ", value: "total_bet_trifecta" },
        { text: "สถานะ", value: "status" },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" }
      ],
      expendData: []
    };
  },
  computed: {
    page: {
      get() {
        return parseInt(this.meta.currentPage);
      },
      set(value) {
        this.$emit("setCurrentPage", parseInt(value));
      }
    },
    itemPerPage: {
      get() {
        return parseInt(this.meta.itemsPerPage);
      },
      set(value) {
        this.$emit("selectPage", value);
      }
    }
  },
  watch: {
    expanded(value) {
      if (!value) return;
      this.expendData = [];
      this.findAll(value);
    }
  },
  methods: {
    async findAll(values) {
      try {
        try {
          const { data, status } = await HorseService.findAllMatch({
            page: 1,
            limit: 100,
            match_date: values[0].date
          });

          if (status === 200) {
            this.expendData = data.items;
            // this.meta = data.meta;
          }
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkScope(value) {
      return !!this.scope.find(item => item === value);
    },
    redirectDetail(values) {
      this.$router.push({
        path: "/bet/detail",
        query: {
          date: moment(new Date(values.date))
            .utc()
            .format("L"),
          name: values.name
        }
      });
    },

    permission(action) {
      return !this.role ? true : actionPermisstion(this.role, action);
    }
  }
};
</script>
