<template>
  <v-navigation-drawer
    v-model="createDrawer"
    fixed
    temporary
    right
    top
    :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
    color="#289672"
  >
    <v-row no-gutters justify="end">
      <v-col cols="12">
        <v-toolbar
          flat
          dark
          dense
          color="transparent"
          width="100%"
          max-height="50px"
        >
          <v-btn icon dark @click="onClose" class="focus:outline-none">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
          <span v-if="!editID">เติมเครดิต</span>
          <span v-else>แก้ไขเครดิต</span>
        </h1>

        <v-row no-gutters justify="center" class="pa-4">
          <v-col cols="12" lg="10">
            <v-form class="ma-0 pa-0" ref="form" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">สมาชิก</p>
                  <v-combobox
                    :rules="nameRules"
                    required
                    :disabled="editID ? true : false"
                    v-model="createForm.memberId"
                    :items="memberItems"
                    :search-input.sync="memberSearch"
                    color="#fff"
                    item-value="id"
                    item-text="username"
                    dark
                    :clearable="!editID ? true : false"
                    v-bind="textFieldProp"
                  >
                    <template v-slot:item="{ item }">
                      <span class="px-2"
                        >{{ item.username }} : {{ item.name }}</span
                      >
                    </template>

                    <template v-slot:selection="{ item }">
                      <span class="px-2"
                        >{{ item.username }} : {{ item.name }}</span
                      >
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">จำนวน</p>
                  <v-text-field
                    :disabled="editID ? true : false"
                    dark
                    required
                    type="number"
                    v-model="createForm.amount"
                    v-bind="textFieldProp"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="6" sm="6" md="6" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">โบนัส</p>

                  <v-combobox
                    v-model="createForm.bonusId"
                    :items="bonusItems"
                    :disabled="editID ? true : false"
                    cache-items
                    item-value="id"
                    item-text="title"
                    color="#fff"
                    dark
                    clearable
                    v-bind="textFieldProp"
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="12" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    เวลา SMS
                  </p>
                  <v-text-field
                    dark
                    v-model="createForm.sms_time"
                    v-bind="textFieldProp"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="px-2 py-0">
                  <v-switch
                    v-model="createForm.is_register"
                    label="สมัครสมาชิก"
                    dark
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="px-2 py-0">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    รายละเอียด
                  </p>
                  <v-textarea
                    dark
                    rows="3"
                    v-model="createForm.description"
                    v-bind="textFieldProp"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="px-2 py-0" v-if="!editID">
                  <v-switch
                    v-model="createForm.update_credit"
                    label="อัพเดทเครดิต"
                    dark
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row justify="start" no-gutters>
                <v-col cols="12" lg="6" sm="6" md="6" class="px-4">
                  <v-btn
                    block
                    large
                    dark
                    outlined
                    depressed
                    @click="confirmCreateDialog = true"
                    class="focus:outline-none border-2 rounded-lg"
                  >
                    <template v-slot:default>
                      <span class="text-bold text-lg">บันทึก</span>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
      <v-card tile flat>
        <v-card-text>
          <h1 class="text-xl font-bold mt-4 mb-2">
            <span v-if="!editID">เพิ่มเครดิต</span>
            <span v-else>แก้ไขเครดิต</span>
          </h1>
          <p class="text-sm font-light">
            <span v-if="!editID"> คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span>
            <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
          </p>
        </v-card-text>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-btn
              color="error"
              depressed
              text
              tile
              @click="confirmCreateDialog = false"
            >
              ยกเลิก
            </v-btn>

            <v-btn color="success" text depressed tile @click="onSubmit">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    editID: {
      type: [String, Number],
      default: null
    },
    bonusItems: {
      type: [Array, Object],
      default: () => []
    },
    memberItems: {
      type: [Array, Object],
      default: () => []
    },
    createForm: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      nameRules: [v => !!v || "*กรุณากรอกข้อมูล"],
      member: {},
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#1e6f5c"
      },
      confirmCreateDialog: false,
      memberSearch: null
    };
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.createForm.amount = parseFloat(this.createForm.amount);
        this.createForm.credit_before = parseFloat(this.createForm.credit_before); 
        this.createForm.credit_after = parseFloat(this.createForm.credit_after);
        this.createForm.inviteId = this.createForm?.memberId?.invite?.id
        this.createForm.memberId = this.createForm?.memberId?.id 
        this.createForm.bonusId = this.createForm?.bonusId?.id

        !this.editID
          ? this.$emit("onSubmitDeposit", this.createForm)
          : this.$emit("onUpdateDeposit", this.createForm);

        this.onClose();
        this.confirmCreateDialog = false;
      } else {
        this.confirmCreateDialog = false;
      }
    },
    dateTime(value) {
      let dateLocal = "";
      const date = new Date(value);
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      const setDate = new Date(year, month, day);
      dateLocal = setDate.toISOString().substring(0, 10);
      return `${dateLocal}  ${date.toLocaleTimeString("th-TH", {
        hour: "2-digit",
        minute: "2-digit"
      })}`;
    },
    onClose() {
      this.$emit("onClose", false);
    }
  },
  watch: {
    memberSearch(value) {
      this.$emit("memberSearch", value);
    }
  },
  computed: {
    createDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("onClose", value);
        this.$refs.form.resetValidation();
      }
    },
    members() {
      return this.memberItems.map(item => {
        return Object.assign({}, item);
      });
    }
  }
};
</script>
