var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":parseInt(_vm.meta.itemsPerPage),"disable-pagination":"","hide-default-footer":"","expanded":_vm.expanded,"single-expand":true,"show-expand":"","item-key":"index"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"single-expand":true,"headers":_vm.expendHeaders,"items":_vm.expendData,"items-per-page":5,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.redirectDetail(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.created_at))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.date))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeLocal")(item.updated_at))+" ")]}}],null,true)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"label":"Items per Page","items":_vm.pageSizes,"outlined":"","dense":""},model:{value:(_vm.itemPerPage),callback:function ($$v) {_vm.itemPerPage=$$v},expression:"itemPerPage"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{attrs:{"color":"purple","length":_vm.meta.totalPages,"total-visible":"5","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }